<template>
  <div style="background-color: #ffffff;width: 100vw;padding: 16px;box-sizing: border-box">
    <!--    <div style="position: relative">-->
    <!--      <van-swipe autoplay="3000" loop style="width: 100vw">-->
    <!--        <van-swipe-item v-for="(image, index) in images" :key="index" style="width: 100vw">-->
    <!--          <my-img :url="image" style="width: 100vw"/>-->
    <!--        </van-swipe-item>-->
    <!--      </van-swipe>-->
    <!--      <my-img style="width: 66%;position: absolute;top: 2px;left: 2px"-->
    <!--              url="https://cdn.keihong.tech/img/202408232318097.png"/>-->
    <!--      <div style="padding: 0 16px;position:absolute; bottom: -48px;left: 0" @click="onIntroClick">-->
    <!--        <my-img style="width: 100%;"-->
    <!--                url="https://cdn.keihong.tech/img/202411151731131.png"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div style="height: 56px"></div>-->
    <div v-if="test">{{ test }}</div>
    <div style="margin-bottom: 16px">
      <my-img style="width: 100%;"
              url="https://cdn.keihong.tech/img/202501101558735.png"/>
    </div>
    <div style="position:relative;margin-bottom: 16px">
      <!--  公告牌    -->
      <my-img style="width: 100%;"
              url="https://cdn.keihong.tech/img/202501101600447.png"/>
      <div v-if="gonggao.length > 0"
           style="position: absolute;top:16px;bottom: 16px;left: 32px;width:60vw;font-size: 18px;max-lines: 3;display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    color: #2e2e2e;
    line-height: 1;
    word-wrap: break-word;">
        {{ gonggao[0].content }}
      </div>
    </div>
    <div class="func-wrapper">
      <div class="left">
        <!--          <img alt="Long Image 1" src="https://cdn.keihong.tech/img/202412091604195.png"/>-->
        <!--          <img alt="Long Image 1" src="https://cdn.keihong.tech/img/202412091604410.png"/>-->
        <my-img url="https://cdn.keihong.tech/img/202501101644072.png"
                @click.native="navigateTo('/JeilongDingcanPage')"/>
        <my-img url="https://cdn.keihong.tech/img/202501101643290.png"
                @click.native="navigateTo('/mdt/home')"/>
        <my-img url="https://cdn.keihong.tech/img/202412091604410.png"
                @click.native="navigateTo('/KepuxuanjiaoMainPage')"/>
      </div>
      <div class="right">
        <my-img url="https://cdn.keihong.tech/img/202501101644814.png" @click.native="navigateTo('/PingTuanHomePage')"/>
        <my-img url="https://cdn.keihong.tech/img/202412091604431.png"
                @click.native="navigateTo('/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=上门护理&notShowMenu=true')"/>
        <my-img url="https://cdn.keihong.tech/img/202501101645741.png"
                @click.native="navigateTo('/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=上门康复&notShowMenu=true')"/>
        <my-img url="https://cdn.keihong.tech/img/202501101645525.png"
                @click.native="navigateTo('/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=社区康复&notShowMenu=true')"/>
        <my-img url="https://cdn.keihong.tech/img/202412091604490.png"
                @click.native="navigateTo('/planListPage')"/>
        <my-img url="https://cdn.keihong.tech/img/202412091605494.png"
                @click.native="navigateTo('/PatientDataHomePage')"/>
        <my-img url="https://cdn.keihong.tech/img/202501101646619.png"
                @click.native="navigateTo('/MallMainPageNeimeng?hospital=宏乾健康&mode=动优辅具&type=生活超市')"/>

      </div>
    </div>
    <div style="height: 32px"></div>
    <div class="doctor-list-wrapper">
      <div style="margin-bottom: 16px;font-size: 18px">全部专家</div>
      <my-img v-for="(doctor,index) in hospital.doctors" :key="index" :url="doctor.img"
              style="width: 100%;margin-bottom: 16px"/>
    </div>

    <div style="margin-top: 64px;font-size: 12px;color: #505050;text-align: center;margin-bottom: 16px">
      技术支持：动优科技
    </div>
    <div style="height: 64px"></div>
    <!--    <div>-->
    <!--      <ServiceImageButtonGroup-->
    <!--        :leftImage="'https://cdn.keihong.tech/img/202411151758264.png'"-->
    <!--        :rightItems="serviceItems1"-->
    <!--      />-->
    <!--      <div style="height: 16px"/>-->
    <!--      &lt;!&ndash;-->
    <!--              :left-link="'#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务'"-->
    <!--          &ndash;&gt;-->
    <!--      <ServiceImageButtonGroup-->
    <!--        :leftImage="'https://cdn.keihong.tech/img/202411151818876.png'"-->
    <!--        :rightItems="serviceItems2"-->
    <!--      />-->
    <!--      <div style="height: 16px"/>-->
    <!--      <ServiceImageButtonGroup-->
    <!--        :leftImage="'https://cdn.keihong.tech/img/202411151821222.png'"-->
    <!--        :rightItems="serviceItems3"-->
    <!--      />-->
    <!--    </div>-->

    <!--    <div style="margin-top: 64px;font-size: 12px;color: #505050;text-align: center;margin-bottom: 16px">-->
    <!--      技术支持：动优科技-->
    <!--    </div>-->

  </div>
</template>
<script>
import MyImg from '@/h5/doucan/components/MyImg.vue'
import ServiceImageButtonGroup from '@/h5/doucan/components/ServiceImageButtonGroup.vue'
import DescpDao from '@/h5/doucan/network/DescpDao'

export default {
  name: 'HongQianHomePage',
  components: {
    ServiceImageButtonGroup,
    MyImg
  },
  data () {
    return {
      test: '',
      gonggao: [],
      hospital: {},
      images: [
        'https://cdn.keihong.tech/img/202411161337244.jpg',
        'https://cdn.keihong.tech/img/202411161337784.jpg',
        'https://cdn.keihong.tech/img/202411161337195.jpg'
      ],
      serviceItems1: [
        {
          image: 'https://cdn.keihong.tech/img/202411151803944.png',
          title: '',
          link: '#/LoginCheckPage?route=/JeilongDingcanPage'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151759609.png',
          title: '',
          link: '#/PingTuanHomePage'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151803888.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&mode=动优辅具&type=生活超市'
        }
      ],
      serviceItems2: [
        {
          image: 'https://cdn.keihong.tech/img/202411151819825.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=上门护理&notShowMenu=true'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411161239029.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=上门康复&notShowMenu=true'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411161240018.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=社区康复&notShowMenu=true'
        }
      ],
      serviceItems3: [
        {
          image: 'https://cdn.keihong.tech/img/202411151820537.png',
          title: '',
          link: '#/LoginCheckPage?route=/mdt/home'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151820016.png',
          title: '',
          link: '#/LoginCheckPage?route=/planListPage'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151821047.png',
          title: '',
          link: '#/LoginCheckPage?route=/PatientDataHomePage'
        }
      ]
    }
  },
  mounted () {
    if (this.$route.query.openid) {
      this.$store.commit('setIsWxapp', true)
      this.$store.commit('setWxAppOpenId', this.$route.query.openid)
      // this.test = this.$store.state.wxAppOpenId
    }
    this.hospital = this.getHospitalById('56')
    console.log(this.hospital)
    this.loadGonggao()
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    navigateTo (url) {
      const isLogin = this.$store.state.user.profile.isLogin
      if (isLogin) {
        if (url === '/mdt/home' && window.wx?.miniProgram) {
          window.wx.miniProgram.navigateTo({
            url: `/pages/mdt_list/mdt_list?from=web&phone=${this.$store.state.user.profile.phone}`
          })
        } else {
          this.$router.push(url)
        }
      } else {
        this.$router.push('/login/index')
      }
      // this.$router.push(url)
    },
    getHospitalById (id) {
      const hospitalList = this.$store.state.miniappWebHospitalList.datas
      for (let i = 0; i < hospitalList.length; i++) {
        if (hospitalList[i].id === id) {
          return hospitalList[i]
        }
      }
      return null
    },
    loadGonggao () {
      const qrCodeHospitalId = this.$store.state.user.qrCodeHospitalId
      DescpDao.findByTaxon('gonggao').then((res) => {
        console.log(res)
        const gonggaoPackage = JSON.parse(res)
        console.log(gonggaoPackage)
        let gonggaoDatas = gonggaoPackage[qrCodeHospitalId]
        // 移除 status为false的数据
        gonggaoDatas = gonggaoDatas.filter((item) => item.status)
        this.gonggao = gonggaoDatas
        console.log(this.gonggao)
      })
    },
    onIntroClick () {
      // 跳转url https://mp.weixin.qq.com/s/VM8IMKPS2wlMS-xU23SxGQ
      window.location.href = 'https://mp.weixin.qq.com/s/VM8IMKPS2wlMS-xU23SxGQ'
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

.func-wrapper {
  display: flex;
  gap: 8px;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.left {
  flex: 7.6;
}

.right {
  flex: 11.1;
  gap: 15px;
}

.left img,
.right img {
  width: 100%;
  object-fit: cover; /* 保证图片覆盖区域 */
  flex: 1; /* 均分父容器高度 */
}

.left img:first-child {
  flex: 1 1 50%; /* 第一个长图占据父容器的50% */
}

.right img {
  flex: 1 1 20%; /* 每个短图占据父容器的20% */
}

.doctor-list-wrapper {
  opacity: 1;
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 10%, #F9F9F9 89%, #FFFFFF 100%);
  padding: 16px;
}
</style>
