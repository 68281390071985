<template>
  <div style="margin-bottom: 48px">
    <div class="main">
      <div v-if="name" style="text-align: center; font-size: 18px">
        当前用户：{{ name }}
      </div>
      <img
        class="img_item"
        src="https://cdn.keihong.tech/typora/2022-09-25-%E5%BA%B7%E5%A4%8D%E4%BD%93%E6%93%8D%E5%85%8D%E8%B4%B9%E7%94%B3%E8%AF%B72x.png"
        @click="toApplyGymnastics"
      />
      <img
        class="img_item"
        src="https://cdn.keihong.tech/typora/2022-09-25-%E6%9F%A5%E7%9C%8B%E8%AE%AD%E7%BB%83%E6%96%B9%E6%A1%88.png"
        @click="toPlan"
      />
      <img
        class="img_item"
        src="https://cdn.keihong.tech/img/202407280850164.png"
        @click="toMdt"
      />
      <div
        style="color: white;margin: 16px;background-color: #ff8800;padding: 16px;border-radius: 16px;font-size: 24px;font-weight: bold;text-align: center"
        @click="toDaka">
        打卡中心
      </div>
      <Grid v-if="functionItems" :column-num="4" :image-array="functionItems" style="width: 100%;padding: 32px"
            @click="handleFuntionMenuClick"/>
      <!-- <img
        class="img_item" @click="toHomeServiceApply"
        src="https://cdn.keihong.tech/typora/2022-11-28-btn_%E4%B8%93%E9%A1%B9%E6%9C%8D%E5%8A%A1%E8%B5%84%E5%8A%A92.png"> -->
      <!--      <div style="width: 100%;height: 48px;text-align: center;background-color: #0d3d3d;line-height: 48px;color: white"-->
      <!--           @click="toQuickPayServe">服务快捷购买-->
      <!--      </div>-->
    </div>
    <div style="padding: 16px">
      <div style="height: 30vh"></div>
      <van-button style="width: 100%" @click="logout">退出账号</van-button>
    </div>
  </div>
</template>

<script>
import Grid from '@/h5/doucan/components/Grid.vue'

export default {
  name: 'Home',
  components: { Grid },
  data () {
    return {
      name: '',
      iosVer: '',
      functionItems: null
    }
  },
  mounted () {
    if (this.$store.state.user.profile.isLogin) {
      this.name =
        this.$store.state.user.profile.name +
        '（' +
        this.$store.state.user.profile.phone.slice(7, 11) +
        '）'
    }

    if (this.$store.state.user.canShowMall) {
      this.functionItems = [
        {
          url: 'https://cdn.keihong.tech/img/202405171350587.png',
          name: '操作指南'
        },
        {
          url: 'https://cdn.keihong.tech/202311241620941.png',
          name: '订单中心'
        }

      ]
    } else {
      this.functionItems = [
        {
          url: 'https://cdn.keihong.tech/img/202405171350587.png',
          name: '操作指南'
        }

      ]
    }
  },
  methods: {
    handleFuntionMenuClick (item, index) {
      console.log('点击', item, index)
      if (item.name === '订单中心') {
        this.$router.push('/OrderHomePage')
      }
      if (item.name === '操作指南') {
        this.$router.push('/UserGuide?showpatient=true')
      }
    },
    toHomeServiceApply () {
      this.$router.push('/HomeApplyZxfwzz')
    },
    toOtherService () {
      window.location.href = 'https://www.wjx.cn/vm/OPAidTV.aspx?v=13'
    },
    toDaka () {
      if (!this.checkLogin()) {
        this.$router.push('/login/index')
        return
      }
      this.$router.push('/PatientDataHomePage')
    },
    toApplyGymnastics () {
      if (!this.checkLogin()) {
        this.$router.push('/login/index')
        return
      }
      this.$router.push('/apply/gymnastics')
    },
    toPlan () {
      if (!this.checkLogin()) {
        this.$router.push('/login/index')
        return
      }
      this.$router.push('/plangymnasticsSel')
    },
    toMdt () {
      if (!this.checkLogin()) {
        this.$router.push('/login/index')
        return
      }
      this.$router.push('/mdt/home')
    },
    checkLogin () {
      return this.$store.state.user.profile.isLogin
    },
    logout () {
      // this.$store.commit('user/setQrCodeHospital', '')
      this.$store.commit('user/setOpenId', '')
      this.$store.commit('user/logout')
      location.reload()
    }
  }
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
}

.main {
  padding-top: 48px;
  padding-bottom: 48px;
}
</style>
