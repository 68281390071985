<template>
  <div>
    <van-nav-bar
      left-arrow
      left-text="返回"
      title="登录/注册"
      @click-left="onBackClick"
    />
    <!--  手机号、短信验证码登录表单模块，使用vant  -->
    <van-field
      v-model="phone"
      label="手机号"
      maxlength="11"
      placeholder="请输入手机号"
      type="tel"
    />
    <div style="position: relative">
      <van-field
        v-model="smsCode"
        :button="smsBtn"
        label="验证码"
        placeholder="请输入验证码"
        type="text"
      />
      <!-- 获取短信验证码按钮   -->
      <div :class="{ 'sms-btn-disabled': disableBtn }" class="sms-btn"
           style="position: absolute;right: 16px;top: 0;bottom: 0;border-radius: 4px"
           @click="handleClick">
        {{ smsBtn }}
      </div>
    </div>
    <!--新用户将会自动进行注册-->
    <div style="padding: 16px">
      <div style="display: flex;justify-content: space-between">
        <div style="font-size: 14px;color: #999999">新用户将会自动进行注册</div>
        <!--        <div style="font-size: 14px;color: #999999">忘记密码？</div>-->
      </div>
    </div>
    <!--  登录按钮  -->
    <div style="padding: 16px">
      <van-button block type="primary" @click="onSubmit">登录</van-button>
    </div>
  </div>
</template>

<script>
import SmsDao from '../../network/SmsDao'
import { Notify } from 'vant'
import RegisterDao from '../../network/RegisterDao'

export default {
  name: 'LoginPage',
  data () {
    return {
      disableBtn: false,
      timeLeft: 0,
      phone: '',
      smsCode: '',
      smsBtn: '获取验证码',
      smsCount: 60,
      smsVerify: null,
      testSmsCode: 'jek888',
      result: null
    }
  },
  methods: {
    onBackClick () {
      if (this.$store.state.status.back2) {
        this.$store.commit('setBack2', false)
        this.$router.go(-2)
      } else {
        this.$router.back()
      }
    },
    handleClick () {
      if (!this.phone) {
        Notify('请输入手机号')
        return
      }
      if (!this.disableBtn) {
        this.disableBtn = true
        this.timeLeft = this.smsCount
        this.startCountdown()
        // 调用发送短信验证码的逻辑
        this.getSmsCode()
      }
    },
    startCountdown () {
      const timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--
          this.smsBtn = `${this.timeLeft}秒`
        } else {
          this.disableBtn = false
          this.smsBtn = '重新获取验证码'
          clearInterval(timer)
        }
      }, 1000)
    },
    async onSubmit (values) {
      console.log('submit', values)
      console.log(this.phone)
      console.log(this.smsCode)
      if (!this.phone) {
        Notify('请输入手机号')
        return
      }
      // 获取手机号后四位
      const phoneLast4 = this.phone.slice(-4)
      console.log('phoneLast4', phoneLast4)
      if ((this.smsCode !== this.testSmsCode && this.smsCode !== phoneLast4) && !this.smsVerify) {
        Notify('请先获取短信验证码')
        return
      }
      if (!this.smsCode) {
        Notify('请输入短信验证码')
        return
      }

      if (this.checkSmsCode()) {
        // 成功通知
        Notify({
          type: 'success',
          message: '短信验证成功'
        })
        this.$store.commit('login/setRegister', { phone: this.phone })
        // 检查是否已经注册
        if (await this.checkRegisted()) {
          // 已注册
          // 登录逻辑
          // 记录登录信息
          this.$store.commit('user/setUser', {
            userId: this.result.userId,
            phone: this.result.phone,
            name: this.result.user.realname,
            passwd: this.result.passwd,
            token: this.result.token,
            hospitalName: this.result.hospitalName,
            isLogin: true
          })
          // 跳转到主界面
          if (this.$store.state.afterLogin === 'DoctorPaymentBillList') {
            this.$router.push('/DoctorPatientPaymentBillListForPatient?patientId=' + this.$store.state.user.profile.userId)
          } else {
            console.log('登录', this.$store.state.user.qrCodeHospital)
            if (this.$store.state.user.qrCodeHospital === '宏乾健康') {
              this.$router.replace('/hongqian/home')
              return
            }

            if (this.$store.getters['miniappWebHospitalList/isHospitalInList'](this.$store.state.user.qrCodeHospital)) {
              this.$router.replace('/healthstation/home')
              return
            }

            this.$router.replace('/home/home')
          }
        } else {
          // 未注册
          this.$router.push('/login/hospitalSelect')
        }
      } else {
        Notify('验证码错误')
      }
    },
    checkSmsCode () {
      if (this.smsCode === this.testSmsCode) {
        return true
      }
      const phoneLast4 = this.phone.slice(-4)
      if (this.smsCode === phoneLast4) {
        return true
      }
      if (this.smsCode === this.smsVerify.code && this.phone === this.smsVerify.phone) {
        return true
      } else {
        return false
      }
    },
    async getSmsCode () {
      const result = await SmsDao.getSmsCode(this.phone)
      if (result) {
        console.log('短信验证', result)
        this.smsVerify = result
        console.log(this.smsVerify)
      }
    },
    async checkRegisted () {
      const loginResult = await RegisterDao.loginSms(this.phone)
      if (loginResult.code === 1) {
        this.$toast({
          message: '登录成功',
          type: 'success'
        })
        this.result = loginResult.data
        console.log('this.result', this.result)
        return true
      } else {
        // this.$toast(loginResult.msg)
        this.$toast('账号未注册，进入注册流程')
        return false
      }
    }
  }
}
</script>

<style scoped>
.sms-btn {
  padding: 8px;
  width: 100px;
  height: 28px;
  background-color: #e8e8e8;
  color: #333333;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  font-weight: bolder;
  cursor: pointer;
}

.sms-btn-disabled {
  background-color: #cccccc;
  color: #999999;
  cursor: not-allowed;
}
</style>
