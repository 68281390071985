<template>
  <!--  <base-title-bar-scroll-page ref="container" title="点餐接龙">-->
  <div>
    <my-nav-bar :title="'点餐接龙'" back @onBackClick="onBack"/>
    <div class="order-page">
      <!-- 日期、标题、描述 -->
      <div class="header">
        <van-cell :value="date" class="large-font custom-cell-1" title="订餐日期"/>
        <van-cell :value="title" class="large-font custom-cell-1" title="订餐标题"/>
        <van-cell :value="description" class="large-font custom-cell-1" title="描述"/>
      </div>
      <!-- 午餐、晚餐选项 -->
      <div class="meal-time-options">
        <van-cell-group>
          <van-radio-group v-model="type">
            <van-radio name="午餐" style="padding-left: 16px">
              <div class="meal-details">
                <h3>午餐</h3></div>
            </van-radio>
            <div style="height: 16px"></div>
            <van-radio name="晚餐" style="padding-left: 16px">
              <div class="meal-details">
                <h3>晚餐</h3></div>
            </van-radio>
          </van-radio-group>
        </van-cell-group>
      </div>
      <!-- 选项1：餐选项 -->
      <div class="meal-options">
        <van-cell-group>
          <van-radio-group v-model="selectedMeal">
            <van-cell v-for="meal in mealOptions" :key="meal.name" class="large-font">
              <van-radio :name="meal">
                <div class="meal-info">
                  <img :src="meal.image" alt="meal image" class="meal-image"/>
                  <div class="meal-details">
                    <h3>{{ meal.name }}</h3>
                    <p>{{ meal.description }}</p>
                  </div>
                </div>
              </van-radio>
            </van-cell>
          </van-radio-group>
        </van-cell-group>
      </div>

      <!-- 选项2：身份选项 -->
      <div class="identity-options">
        <van-cell-group>
          <van-radio-group v-model="identity">
            <van-radio v-for="option in identityOptions" :key="option.type" :name="option.type"
                       class="large-font" style="margin-left: 16px;border: #0c4fa8 ">
              <div class="identity-info">
                <h3>{{ option.type }}</h3>
                <div style="display: flex">
                  <p>{{ option.description }}</p>
                  <p v-if="option.type !== '长者'" style="color: #0c4fa8">,价格：{{ selectedMeal.price }}</p>
                </div>
              </div>
            </van-radio>
          </van-radio-group>
        </van-cell-group>
      </div>

      <!-- 姓名、手机号输入 -->
      <div class="user-info">
        <van-field v-model="userName" class="large-font" label="姓名" placeholder="请输入您的姓名"/>
        <van-field v-model="userPhone" class="large-font" label="手机号" placeholder="请输入您的手机号" type="tel"/>
      </div>

      <!-- 提交按钮 -->
      <div class="submit-button"
           style="z-index: 999;position: fixed;bottom: 0;width: 100%;left: 0;padding: 16px;box-sizing: border-box">
        <van-button class="large-font" style="width: 100%;border-radius: 16px" type="primary" @click="submitOrder">
          提交订餐信息
        </van-button>
      </div>

      <!-- 接龙模块-列表 -->
      <div class="join-list">
        <h3>我的订餐</h3>
        <div v-for="(person,index) in joinListMine" :key="person.id" class="join-item" style="border-radius: 16px">
          <van-cell :title="`${index+1}、${person.name} ${person.phone}`" class="large-font" style="border-radius: 16px">
            <p style="color: black">{{ person.type }}、{{ person.meal }}</p>
            <p style="color: black">身份：{{ person.identity }}</p>
          </van-cell>
        </div>
      </div>

      <div class="join-list">
        <h3>接龙列表-午餐</h3>
        <div v-for="(person,index) in joinListLunch" :key="person.id" class="join-item" style="border-radius: 16px">
          <van-cell :title="`${index+1}、${person.name} ${person.phone}`" class="large-font  custom-cell"
                    style="border-radius: 16px">
            <p style="color: black">{{ person.type }}、{{ person.meal }}</p>
            <p style="color: black">身份：{{ person.identity }}</p>
          </van-cell>
        </div>
      </div>
      <div class="join-list">
        <h3>接龙列表-晚餐</h3>
        <div v-for="(person,index) in joinListDinner" :key="person.id" class="join-item">
          <van-cell :title="`${index+1}、${person.name} ${person.phone}`" class="large-font  custom-cell">
            <p style="color: black">{{ person.type }}、{{ person.meal }}</p>
            <p style="color: black">身份：{{ person.identity }}</p>
          </van-cell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import MyNavBar from '@/h5/doucan/components/MyNavBar.vue'
import DateUtils from '@/utils/DateUtils'
import BookingMealDao from '@/h5/doucan/network/BookingMealDao'

export default {
  components: {
    MyNavBar,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      openId: '',
      date: '',
      title: '订餐接龙',
      description: '请在此选择您的餐点和身份',
      mealOptions: [
        {
          name: 'A餐',
          description: '暂未填写菜品描述',
          price: 12,
          image: 'https://cdn.keihong.tech/img/202411231325782.png'
        },
        {
          name: 'B餐',
          description: '暂未填写菜品描述',
          price: 15,
          image: 'https://cdn.keihong.tech/img/202411231325782.png'
        },
        {
          name: 'C餐',
          description: '暂未填写菜品描述',
          price: 18,
          image: 'https://cdn.keihong.tech/img/202411231325782.png'
        }
      ],
      identityOptions: [
        {
          type: '长者',
          description: '享受社区补贴，优惠价'
        },
        {
          type: '社区居民',
          description: '标准价（无补贴）'
        }
      ],
      selectedMeal: null,
      identity: '',
      userName: '',
      userPhone: '',
      type: '',
      joinListMine: [
        {
          name: '我',
          meal: 'A餐',
          identity: '老人长者',
          phone: '12345678901',
          type: '午餐'
        }
      ],
      joinListLunch: [
        {
          name: '张三',
          meal: 'A餐',
          identity: '老人长者',
          phone: '12345678901',
          type: '午餐'
        }
      ],
      joinListDinner: [
        {
          name: '李四',
          meal: 'B餐',
          identity: '非老人长者',
          phone: '09876543210',
          type: '晚餐'
        }
      ]
    }
  },
  async mounted () {
    // 默认选中第一个
    this.selectedMeal = this.mealOptions[0]
    this.openId = this.$route.query.openId
    if (!this.openId) {
      this.openId = this.$store.state.user.profile.phone
    }
    console.log('openId', this.openId)
    // 生成当天日子,如'2024-11-15'
    this.date = DateUtils.formateDate(new Date(), 'yyyy-MM-DD')
    await this.loadData()
    if (this.$store.state.user.profile.phone) {
      this.userPhone = this.$store.state.user.profile.phone
    }
    if (this.$store.state.user.profile.name) {
      this.userName = this.$store.state.user.profile.name
    }
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      // this.$refs.container.stopLoading()
    },
    onBack () {
      if (this.$store.state.user.qrCodeHospital === '宏乾健康') {
        this.$router.push('/hongqian/home')
      } else {
        this.$router.back()
      }
    },
    async submitOrder () {
      if (this.userName && this.userPhone && this.selectedMeal && this.identity && this.type) {
        const submitData = {
          name: this.userName,
          meal: this.selectedMeal.name,
          price: this.selectedMeal.price,
          identity: this.identity,
          phone: this.userPhone,
          type: this.type,
          openId: this.openId
        }
        console.log('submitData', submitData)
        // this.joinList.push(submitData)
        const res = await BookingMealDao.add(submitData)
        console.log('提交接龙 res', res)
        if (res.code === 200) {
          this.$dialog.alert({
            title: '温馨提示',
            message: '接龙成功'
          }).then(() => {
            this.loadData()
            // on close
            // 跳转回 /AdminPage,且不可返回
            // this.$router.replace({
            //   path: '/AdminHomePage'
            // })
          })
          // this.$router.push({
          //   path: '/mdtlist'
          // })
        } else {
          this.$dialog.alert({
            title: '温馨提示',
            message: '您今天已经订过午餐/晚餐了,请查看我的订餐'
          }).then(() => {
            this.loadData()
            // on close
            // 跳转回 /AdminPage,且不可返回
            // this.$router.replace({
            //   path: '/AdminHomePage'
            // })
          })
        }
        // this.userName = ''
        // this.userPhone = ''
        // this.selectedMeal = ''
        // this.identity = ''
        this.type = ''
        if (this.$store.state.user.profile.phone) {
          this.userPhone = this.$store.state.user.profile.phone
        }
        if (this.$store.state.user.profile.name) {
          this.userName = this.$store.state.user.profile.name
        }
      } else {
        this.$toast.fail('您还有选项或信息未填写')
      }
    },
    async loadData () {
      const res = await BookingMealDao.getTodayList()
      console.log(res)
      this.parseData(res)
    },
    parseData (data) {
      const myOpenId = this.openId
      const joinListMine = []
      const joinListLunch = []
      const joinListDinner = []

      // 遍历午餐数据
      data.lunch.forEach(item => {
        if (item.openId === myOpenId) {
          joinListMine.push({
            name: item.name,
            meal: item.meal,
            identity: item.identity,
            phone: item.phone,
            type: item.type
          })
        }

        joinListLunch.push({
          name: item.name,
          meal: item.meal,
          identity: item.identity,
          phone: item.phone,
          type: item.type
        })
      })

      // 遍历晚餐数据
      data.dinner.forEach(item => {
        if (item.openId === myOpenId) {
          joinListMine.push({
            name: item.name,
            meal: item.meal,
            identity: item.identity,
            phone: item.phone,
            type: item.type
          })
        }

        joinListDinner.push({
          name: item.name,
          meal: item.meal,
          identity: item.identity,
          phone: item.phone,
          type: item.type
        })
      })

      this.joinListMine = joinListMine
      this.joinListDinner = joinListDinner
      this.joinListLunch = joinListLunch
    }
  }
}
</script>

<style scoped>
.order-page {
  padding: 16px;
  background-color: #f4f4f4;
  font-size: 1.1em;
  margin-bottom: 48px;

}

.header {
  margin-bottom: 16px;
  border-radius: 16px;
  padding: 16px 0;
  background-color: #fff;
  border: #aeaeae 1px solid;
}

.meal-options {
  margin-bottom: 16px;
  border-radius: 16px;
  padding: 16px 0;
  background-color: #fff;
  border: #aeaeae 1px solid;
}

.meal-info {
  display: flex;
  align-items: center;
}

.meal-image {
  width: 50px;
  height: 50px;
  margin-right: 16px;
  border-radius: 8px;
}

.meal-details {
  flex: 1;
}

.identity-options {
  margin-bottom: 16px;
  border-radius: 16px;
  padding: 16px 0;
  background-color: #fff;
  border: #aeaeae 1px solid;
}

.identity-info {
  display: flex;
  flex-direction: column;
}

.user-info {
  margin-bottom: 16px;
  border-radius: 16px;
  padding: 16px 0;
  background-color: #fff;
  border: #aeaeae 1px solid;
}

.join-list {
  margin-top: 24px;
}

.join-item {
  border: #aeaeae 1px solid;
}

.meal-time-options {
  margin-bottom: 16px;
  border-radius: 16px;
  padding: 16px 0;
  background-color: #fff;
  border: #aeaeae 1px solid;
}

.large-font {
  font-size: 1.1em;
}

.custom-cell .van-cell__value {
  flex: 3; /* 增大右侧内容的比例 */
}

.custom-cell .van-cell__value {
  flex: 2; /* 增大右侧内容的比例 */
}
</style>
