<template>
  <div class="container">
    <div v-if="result" style="padding: 0 16px;text-align: center;position:relative;">
      <div style="padding: 0 32px">
        <img src="https://cdn.keihong.tech/img/202408152204659.png" style="display: block;width: 100%;">
      </div>
      <div
        style="background-color: white;min-height: 200px;border-radius: 16px;margin-top: -20px;z-index: 1;position:relative;padding: 16px">

        <div style="display: flex;flex-direction: row;width: 100%">
          <div style="width: 4px;height: 20px;padding: 4px 0;margin-right: 8px;box-sizing: border-box">
            <div style="background-color: #D6D6D6;width: 100%;height: 100%"></div>
          </div>
          <div style="width:100%;font-size: 14px;line-height: 20px">
            <div style="display: flex;flex-direction: row;justify-content: space-between;width: 100%">
              <div>订单编号</div>
              <div>{{ result.outTradeNo }}</div>
            </div>
            <div style="display: flex;flex-direction: row;justify-content: space-between;width: 100%">
              <div>支付时间</div>
              <div>{{ result.orderCreateTime }}</div>
            </div>
          </div>
        </div>
        <van-divider :style="{ borderStyle: 'dashed' }"/>
        <div style="display: flex;flex-direction: row;width: 100%">
          <div style="width: 4px;height: 20px;padding: 4px 0;margin-right: 8px;box-sizing: border-box">
            <div style="background-color: #D6D6D6;width: 100%;height: 100%"></div>
          </div>
          <div style="width:100%;font-size: 14px;line-height: 20px">
            <div style="display: flex;flex-direction: row;justify-content: space-between;width: 100%">
              <div>康复服务</div>
              <div style="text-align: right;white-space: pre-wrap">
                {{ result.service }}

              </div>
            </div>
          </div>
        </div>
        <van-divider :style="{ borderStyle: 'dashed' }"/>
        <div style="display: flex;flex-direction: row;width: 100%">
          <div style="width: 4px;height: 20px;padding: 4px 0;margin-right: 8px;box-sizing: border-box">
            <div style="background-color: #D6D6D6;width: 100%;height: 100%"></div>
          </div>
          <div style="width:100%;font-size: 14px;line-height: 20px">
            <div style="display: flex;flex-direction: row;justify-content: space-between;width: 100%">
              <div>患者信息</div>
              <div style="text-align: right;white-space: pre-wrap"> {{ result.patientInfo }}

              </div>
            </div>

          </div>
        </div>

        <img :src="kefuImg" style="display: block;width: 100%;margin-top: 8px">
        <!--        <van-button style="width: 100%;border-radius: 100px" type="primary" @click="refreshPage">确定</van-button>-->
      </div>

    </div>

  </div>
</template>
<script>

export default {
  name: 'QuickPayResult',
  data () {
    return {
      result: null,
      remarkArr: [],
      kefuImg: 'https://cdn.keihong.tech/img/202408152212207.png'
    }
  },
  mounted () {
    if (this.$route.query.data) {
      this.result = JSON.parse(this.$route.query.data)
    }

    if (!this.result) {
      this.result = {
        outTradeNo: '202312211813200',
        orderCreateTime: '2023-12-21 18:13:20',
        remark: '远程康复服务单-1周-暨南大学附属顺德医院-康复医学科-陈强-脑梗塞-陈其康-136777657653'
      }
    }
    // if (this.remarkArr.includes('动龄健康')) {
    //   this.kefuImg = 'https://cdn.keihong.tech/img/202412221420410.jpg'
    // }
    // 把remark拆分
    this.remarkArr = this.result.remark.split('-')
    if (this.remarkArr[0] === 'C端推广活动20241101') {
      this.result.service = `${this.remarkArr[1]}-${this.remarkArr[2]}`
      this.result.patientInfo = `${this.remarkArr[3]}-${this.remarkArr[4]}`
      this.kefuImg = 'https://cdn.keihong.tech/img/202412221431977.png'
    } else {
      this.result.service = this.remarkArr[0] + '，' + this.remarkArr[1] + '\n' + this.remarkArr[2] + '\n' + this.remarkArr[3] + '-' + this.remarkArr[4]
      this.result.patientInfo = this.remarkArr[5] + '\n' + this.remarkArr[6] + '-' + this.remarkArr[7]
    }
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.container {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, #D3FFEC 0%, #94FFD0 29%, #FFFFFF 100%);
}

.success-message {
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
}
</style>
