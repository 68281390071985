//
export default {
  namespaced: true,
  state () {
    return {
      // 用户信息
      datas: [
        {
          id: '56',
          name: '宏乾健康',
          doctors: [
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501101742042.png'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501101742260.png'
            }
          ]
        },
        {
          id: '59',
          name: '霸桥区席王柴马社区卫生服务站',
          mainImg: 'https://cdn.keihong.tech/img/202412091556356.png',
          doctors: [
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202412091637331.png'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202412091639275.png'
            }
          ]
        },
        {
          id: '60',
          name: '瑞仁中医诊所',
          mainImg: 'https://cdn.keihong.tech/img/202412242135051.png',
          doctors: [
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501110538970.jpg'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501110539399.jpg'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501110540199.jpg'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501110540340.jpg'
            }
          ]
        },
        {
          id: '61',
          name: '内蒙古包头市东河区河东镇卫生院分院',
          mainImg: 'https://cdn.keihong.tech/img/202412252153436.png',
          doctors: [
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202412252207917.png'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202412252207133.png'
            }
          ]
        },
        {
          id: '62',
          name: '顺德区容桂花溪社区卫生服务站',
          mainImg: 'https://cdn.keihong.tech/img/202501031131164.png',
          doctors: [
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501031210456.png'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501052056343.png'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501052057233.png'
            }
          ]
        },
        {
          id: '63',
          name: '院后通',
          mainImg: 'https://cdn.keihong.tech/img/202501080106843.png',
          doctors: [
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501031210456.png'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501052056343.png'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501052057233.png'
            }
          ]
        },
        {
          id: '64',
          name: '惠州市康复医学会',
          mainImg: 'https://cdn.keihong.tech/img/202501080114634.png',
          doctors: [
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501031210456.png'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501052056343.png'
            },
            {
              name: '',
              img: 'https://cdn.keihong.tech/img/202501052057233.png'
            }
          ]
        }
      ]
    }
  },
  getters: {
    /**
     * 判断 qrCodeHospital 是否在 datas 数组中
     * @param {Object} state Vuex 的 state
     * @returns {Function} 一个接收 hospitalName 参数的函数，返回布尔值
     */
    isHospitalInList: (state) => (hospitalName) => {
      return state.datas.some((hospital) => hospital.name === hospitalName)
    }
  },
  mutations: {}
}
// ————————————————
// 版权声明：本文为CSDN博主「run-Ameng」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/m0_58861443/article/details/120247013
