import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFoundPage from '../NotFoundPage.vue'
import Index from './pages/Index.vue'
import DoucanHome from './pages/DoucanHome.vue'
import Home from './pages/home/Home.vue'
import Square from './pages/home/Square'
import Person from './pages/home/Person'
import MdtVideo from './pages/mdt/MdtVideo'
import MdtWarning from './pages/mdt/MdtWarning'
import MdtHome from './pages/mdt/MdtHome'
import LoginPage from './pages/login/LoginPage'
import HospitalSelectPage from './pages/login/HospitalSelectPage'
import SickKindSelectPage from './pages/login/SickKindSelectPage'
import DoctorSelectPage from './pages/login/DoctorSelectPage'
import UserBaseInfoEntryPage from './pages/login/UserBaseInfoEntryPage'
import ApplyGymnastics from './pages/apply_gymnastics/ApplyGymnastics'
import PlanGymnasticsSelPage from './pages/plan/PlanGymnasticsSelPage'
import GymnasticsListPage from './pages/plan/GymnasticsListPage'
import GymnasticsDetailPage from './pages/plan/GymnasticsDetailPage'
import PlanListPage from './pages/plan/PlanListPage'
import PlanDetailPage from './pages/plan/PlanDetailPage'
import Home1 from './pages/home/Home1'
import HomeApplyFszyy from './pages/apply/HomeApplyFszyy'
import HomeApplyZxfwzz from './pages/apply/HomeApplyZxfwzz'
import ServeSale202301 from './pages/product_sale/ServeSale202301/ServeSale202301'
import ServeSale202301Step2 from './pages/product_sale/ServeSale202301/ServeSale202301Step2'
import ServeSale202301Step1 from './pages/product_sale/ServeSale202301/ServeSale202301Step1'
import ServeSale202301Step3 from './pages/product_sale/ServeSale202301/ServeSale202301Step3'
import DetailPage from '../feeds/DetailPage'
import DefaultHospitalDownloadPage from './pages/hospitals/download/DefaultHospitalDownloadPage'
import SalePage20230001 from './pages/product_sale/2023001/SalePage20230001'
import SalePageDongguangkanghua01 from './pages/product_sale/dongguangkanghua/01/SalePageDongguangkanghua01.vue'
import SalePage20230001Step2 from './pages/product_sale/2023001/SalePage20230001Step2'
import gmdss from './pages/other/gmdss/gmdss'
import KepuColumn from './pages/kepu/KepuColumn'
import KepuLive from './pages/kepu/KepuLive'
import HospitalIntroDoucan from '../../components/webapp/hospital_intro/HospitalIntroDoucan'
import TicaoList from './pages/kepu/TicaoList.vue'
import TicaoDetailPage from './pages/kepu/TicaoDetailPage.vue'
import SalePage20230001Result from './pages/product_sale/2023001/SalePage20230001Result'
import WebPage from './components/WebPage.vue'
import ServeSale202301NotFree from './pages/product_sale/ServeSale202301/ServeSale202301NotFree.vue'
import MonitoringScreen from './pages/monitoring/MonitoringScreen.vue'
import AdminHomePage from './pages/admin/AdminHomePage.vue'
import WebOrderListPage from './pages/admin/WebOrderListPage.vue'
import PatientList from '@/h5/doucan/pages/admin/PatientList.vue'
import SelectDoctorPage from '@/h5/doucan/pages/admin/SelectDoctorPage.vue'
import DoctorBookingTimeSelectPage from '@/h5/doucan/pages/admin/DoctorBookingTimeSelectPage.vue'
import MDTCreatePage from '@/h5/doucan/pages/admin/MDTCreatePage.vue'
import BookingListPage from '@/h5/doucan/pages/admin/BookingListPage.vue'
import SearchUserPage from '@/h5/doucan/pages/admin/SearchUserPage.vue'
import PayServe1 from '@/h5/doucan/pages/product_sale/pay_serve_1/PayServe1.vue'
import AdminHospital from '@/h5/doucan/pages/admin_hospital/AdminHospital.vue'
import AdminHospitalDoctorList from '@/h5/doucan/pages/admin_hospital/AdminHospitalDoctorList.vue'
import AdminHospitalPatientList from '@/h5/doucan/pages/admin_hospital/AdminHospitalPatientList.vue'
import AdminHospitalPlanList from '@/h5/doucan/pages/admin_hospital/AdminHospitalPlanList.vue'
import AdminHospitalRemoteGuideList from '@/h5/doucan/pages/admin_hospital/AdminHospitalRemoteGuideList.vue'
import BookingManager from '@/h5/doucan/pages/admin_hospital/BookingManager.vue'
import AdminHospitalBookingList from '@/h5/doucan/pages/admin_hospital/AdminHospitalBookingList.vue'
import FreePatientOrderAddPage from '@/h5/doucan/pages/admin/FreePatientOrderAddPage.vue'
import GuidePage from '@/h5/doucan/pages/guide/GuidePage.vue'
import HospitalAdminListPage from '@/h5/doucan/pages/admin/HospitalAdminListPage.vue'
import MallMainPage from '@/h5/doucan/pages/mall/MallMainPage.vue'
import MallDetailPage from '@/h5/doucan/pages/mall/MallDetailPage.vue'
import MallOrderConfirmPage from '@/h5/doucan/pages/mall/MallOrderConfirmPage.vue'
import PayPage from '@/h5/doucan/pages/mall/PayPage.vue'
import MallPayResultPage from '@/h5/doucan/pages/mall/MallPayResultPage.vue'
import ToolKepuHomePage from '@/h5/doucan/pages/kepu/tool_kepu/ToolKepuHomePage.vue'
import KepuxuanjiaoMainPage from '@/h5/doucan/pages/kepu/kepuxuanjiao/KepuxuanjiaoMainPage.vue'
import SingleToolKepuPage from '@/h5/doucan/pages/kepu/tool_kepu/SingleToolKepuPage.vue'
import KepuFromDoctorPage from '@/h5/doucan/pages/kepu/kepuxuanjiao/KepuFromDoctorPage.vue'
import MonitorAdminLabel from '@/h5/doucan/pages/monitoring/MonitorAdminLabel.vue'
import MallMainPageNeimeng from '@/h5/doucan/pages/mall/MallMainPageNeimeng.vue'
import MallRentMainPageNeimeng from '@/h5/doucan/pages/mall/MallRentMainPageNeimeng.vue'
import MallRentDetailPage from '@/h5/doucan/pages/mall/MallRentDetailPage.vue'
import MallRentOrderConfirmPage from '@/h5/doucan/pages/mall/MallRentOrderConfirmPage.vue'
import MyKepuXuanJiaoMainPage from '@/h5/doucan/pages/plan/MyKepuXuanJiaoMainPage.vue'
import OrderHomePage from '@/h5/doucan/pages/Order/OrderHomePage.vue'
import DoctorListPage from '@/h5/doucan/pages/admin/DoctorListPage.vue'
import NeimengPatientBaseInfoForm from '@/h5/doucan/pages/form/NeimengPatientBaseInfoForm.vue'
import AdminHospitalPatientInputList from '@/h5/doucan/pages/admin_hospital/AdminHospitalPatientInputList.vue'
import ClerkLogin from '@/h5/doucan/pages/sys_clerk/ClerkLogin.vue'
import ClerkPage from '@/h5/doucan/pages/sys_clerk/ClerkPage.vue'
import QuickPayPage from '@/h5/doucan/pages/pay/QuickPayPage.vue'
import DoctorPatientPaymentBillCreate
from '@/h5/doucan/pages/doctor_patient_payment_bill/DoctorPatientPaymentBillCreate.vue'
import SalePageFor480 from '@/h5/doucan/pages/product_sale/SalePageFor480.vue'
import DoctorPatientPaymentBillListForPatient
from '@/h5/doucan/pages/doctor_patient_payment_bill/DoctorPatientPaymentBillListForPatient.vue'
import BigScreenMonitor from '@/h5/doucan/pages/monitoring/BigScreenMonitor.vue'
import AdminHospitalPerformanceStatistics from '@/h5/doucan/pages/admin_hospital/AdminHospitalPerformanceStatistics.vue'
import AllianceIntroPage from '@/h5/doucan/pages/AllianceIntroPage.vue'
import EnterpriseLoginPage from '@/h5/doucan/giftcard/enterprise/EnterpriseLoginPage.vue'
import EnterpiseMainPage from '@/h5/doucan/giftcard/enterprise/EnterpiseMainPage.vue'
import ExchangeRemoteServicePage from '@/h5/doucan/giftcard/ExchangeRemoteServicePage.vue'
import ExchangeSuccessPage from '@/h5/doucan/giftcard/ExchangeSuccessPage.vue'
import ExchangeRecordPage from '@/h5/doucan/giftcard/enterprise/ExchangeRecordPage.vue'
import MallOrderListPage from '@/h5/doucan/pages/admin/MallOrderListPage.vue'
import PatientDataHomePage from '@/h5/doucan/pages/daka/PatientDataHomePage.vue'
import MallMainPageV2 from '@/h5/doucan/pages/mall/MallMainPageV2.vue'
import DistributionLoginPage from '@/h5/doucan/pages/distribution/DistributionLoginPage.vue'
import DistributionRegisterPage from '@/h5/doucan/pages/distribution/DistributionRegisterPage.vue'
import DistributionUserPage from '@/h5/doucan/pages/distribution/DistributionUserPage.vue'
import DistributionDetailsPage from '@/h5/doucan/pages/distribution/DistributionDetailsPage.vue'
import UserGuide from '@/h5/doucan/pages/user_guide/UserGuide.vue'
import FeatureTutorial from '@/h5/doucan/pages/user_guide/FeatureTutorial.vue'
import QuickPayNormalPage from '@/h5/doucan/pages/pay/QuickPayNormalPage.vue'
import RuyuanAndChuyuanKepu from '@/h5/doucan/pages/kepu/kepuxuanjiao/RuyuanAndChuyuanKepu.vue'
import SickKepu from '@/h5/doucan/pages/kepu/kepuxuanjiao/SickKepu.vue'
import BedPayKepu from '@/h5/doucan/pages/kepu/kepuxuanjiao/BedPayKepu.vue'
import BaseVideoPlayPage from '@/h5/doucan/pages/kepu/kepuxuanjiao/BaseVideoPlayPage.vue'
import AdminHospitalDakaRecordPage from '@/h5/doucan/pages/admin_hospital/AdminHospitalDakaRecordPage.vue'
import SickKepuShenwai from '@/h5/doucan/pages/kepu/kepuxuanjiao/SickKepuShenwai.vue'
import SickKepuShennei from '@/h5/doucan/pages/kepu/kepuxuanjiao/SickKepuShennei.vue'
import SickKepuKangfuyixueke from '@/h5/doucan/pages/kepu/kepuxuanjiao/SickKepuKangfuyixueke.vue'
import CourseDetailVideoPlayPage from '@/h5/doucan/pages/video_player/CourseDetailVideoPlayPage.vue'
import DistributionMySecondListPage from '@/h5/doucan/pages/distribution/DistributionMySecondListPage.vue'
import DistributionRankPage from '@/h5/doucan/pages/distribution/DistributionRankPage.vue'
import DoucanPrivate from '@/h5/doucan/pages/private_html/DoucanPrivate.vue'
import DoucanUserProtocol from '@/h5/doucan/pages/private_html/DoucanUserProtocol.vue'
import ScreeningTableListPage from '@/h5/doucan/pages/screening/ScreeningTableListPage.vue'
import AssessmentSpine from '@/h5/assessment/spine/AssessmentSpine.vue'
import ScreeningListPage from '@/h5/doucan/pages/plan/ScreeningListPage.vue'
import QuickPaySecurityDeposit1Page from '@/h5/doucan/pages/pay/QuickPaySecurityDeposit1Page.vue'
import ShequDoctorAppDownload from '@/h5/doucan/pages/app_download/ShequDoctorAppDownload.vue'
import QuickPayResult from '@/h5/doucan/pages/pay/QuickPayResult.vue'
import IntroPage from '@/h5/jianerkang/pages/wxapplet/IntroPage.vue'
import QuickPayPageHuizhoupeixun01 from '@/h5/doucan/pages/pay/QuickPayPageHuizhoupeixun01.vue'
import CoursePage from '@/h5/doucan/pages/course_2/CoursePage.vue'
import HongQianHomePage from '@/h5/doucan/pages/thirdpart/hongqian/HongQianHomePage.vue'
import PingTuanHomePage from '@/h5/doucan/pages/thirdpart/hongqian/PingTuanHomePage.vue'
import DiancanJielongHomePage from '@/h5/doucan/pages/thirdpart/hongqian/DiancanJielongHomePage.vue'
import HongqianTabbarHome from '@/h5/doucan/pages/thirdpart/hongqian/HongqianTabbarHome.vue'
import LoginCheckPage from '@/h5/doucan/pages/thirdpart/hongqian/LoginCheckPage.vue'
import HongqianPerson from '@/h5/doucan/pages/thirdpart/hongqian/HongqianPerson.vue'
import DonglingPay289 from '@/h5/doucan/pages/thirdpart/dongling/DonglingPay289.vue'
import DonglingPatientInfoTable from '@/h5/doucan/pages/thirdpart/dongling/DonglingPatientInfoTable.vue'
import QuestionnaireHistoryPage from '@/h5/doucan/pages/thirdpart/dongling/QuestionnaireHistoryPage.vue'
import JeilongDingcanPage from '@/h5/doucan/pages/thirdpart/hongqian/JeilongDingcanPage.vue'
import HealthStationHomePage from '@/h5/doucan/pages/thirdpart/HealthStation/HealthStationHomePage.vue'
import HealthStationPerson from '@/h5/doucan/pages/thirdpart/HealthStation/HealthStationPerson.vue'
import HealthStationHome from '@/h5/doucan/pages/thirdpart/HealthStation/HealthStationHome.vue'
import CheckHospital from '@/h5/doucan/pages/thirdpart/checkHospital/CheckHospital.vue'

Vue.use(VueRouter)

const routes = [
  // CheckHospital
  {
    name: 'CheckHospital',
    path: '/CheckHospital',
    component: CheckHospital,
    meta: {
      title: '检查数据'
    }
  },
  // JeilongDingcanPage
  {
    name: 'JeilongDingcanPage',
    path: '/JeilongDingcanPage',
    component: JeilongDingcanPage,
    meta: {
      title: '订餐接龙'
    }
  },
  // QuestionnaireHistoryPage
  {
    name: 'QuestionnaireHistoryPage',
    path: '/QuestionnaireHistoryPage',
    component: QuestionnaireHistoryPage,
    meta: {
      title: '问卷历史'
    }
  },
  // DonglingPatientInfoTable
  {
    name: 'DonglingPatientInfoTable',
    path: '/DonglingPatientInfoTable',
    component: DonglingPatientInfoTable,
    meta: {
      title: '咨询信息表'
    }
  },
  // DonglingPay289
  {
    name: 'DonglingPay289',
    path: '/DonglingPay289',
    component: DonglingPay289,
    meta: {
      title: '服务介绍'
    }
  },
  // LoginCheckPage
  {
    name: 'LoginCheckPage',
    path: '/LoginCheckPage',
    component: LoginCheckPage,
    meta: {
      title: '检查账号'
    }
  },
  // DiancanJielongHomePage
  {
    name: 'DiancanJielongHomePage',
    path: '/DiancanJielongHomePage',
    component: DiancanJielongHomePage,
    meta: {
      title: '点餐'
    }
  },

  // PingTuanHomePage
  {
    name: 'PingTuanHomePage',
    path: '/PingTuanHomePage',
    component: PingTuanHomePage,
    meta: {
      title: '拼团'
    }
  },
  // HongQianHomePage
  {
    name: 'HongQianHomePage',
    path: '/HongQianHomePage',
    component: HongQianHomePage,
    meta: {
      title: '颐康中心'
    }
  },
  // CoursePage
  {
    name: 'CoursePage',
    path: '/CoursePage',
    component: CoursePage,
    meta: {
      title: '课程详情'
    }
  },
  {
    name: 'IntroPage',
    path: '/introPage',
    component: IntroPage,
    meta: {
      title: '服务介绍'
    }
  },
  // QuickPayResult
  {
    name: 'QuickPayResult',
    path: '/QuickPayResult',
    component: QuickPayResult,
    meta: {
      title: '支付结果'
    }
  },
  // ShequDoctorAppDownload
  {
    name: 'ShequDoctorAppDownload',
    path: '/ShequDoctorAppDownload',
    component: ShequDoctorAppDownload,
    meta: {
      title: '社区医生APP下载'
    }
  },
  // QuickPaySecurityDeposit1Page
  {
    name: 'QuickPaySecurityDeposit1Page',
    path: '/QuickPaySecurityDeposit1Page',
    component: QuickPaySecurityDeposit1Page,
    meta: {
      title: '仪器押金支付'
    }
  },
  // ScreeningListPage
  {
    name: 'ScreeningListPage',
    path: '/ScreeningListPage',
    component: ScreeningListPage,
    meta: {
      title: '筛查列表'
    }
  },
  // AssessmentSpine
  {
    name: 'AssessmentSpine',
    path: '/AssessmentSpine',
    component: AssessmentSpine,
    meta: {
      title: '脊柱侧弯评估'
    }
  },
  // ScreeningTableListPage
  {
    name: 'ScreeningTableListPage',
    path: '/ScreeningTableListPage',
    component: ScreeningTableListPage,
    meta: {
      title: '筛查类型选择'
    }
  },
  // DoucanUserProtocol
  {
    name: 'DoucanUserProtocol',
    path: '/DoucanUserProtocol',
    component: DoucanUserProtocol,
    meta: {
      title: '用户协议'
    }
  },
  // DoucanPrivate
  {
    name: 'DoucanPrivate',
    path: '/DoucanPrivate',
    component: DoucanPrivate,
    meta: {
      title: '隐私政策'
    }
  },
  // DistributionRankPage
  {
    name: 'DistributionRankPage',
    path: '/DistributionRankPage',
    component: DistributionRankPage,
    meta: {
      title: '分销排行'
    }
  },
  // DistributionMySecondListPage
  {
    name: 'DistributionMySecondListPage',
    path: '/DistributionMySecondListPage',
    component: DistributionMySecondListPage,
    meta: {
      title: '我的二级分销团队'
    }
  },
  // CourseDetailVideoPlayPage
  {
    name: 'CourseDetailVideoPlayPage',
    path: '/CourseDetailVideoPlayPage',
    component: CourseDetailVideoPlayPage,
    meta: {
      title: '课程播放'
    }
  },
  // SickKepuKangfuyixueke
  {
    name: 'SickKepuKangfuyixueke',
    path: '/SickKepuKangfuyixueke',
    component: SickKepuKangfuyixueke,
    meta: {
      title: '康复医学科'
    }
  },
  // SickKepuShennei
  {
    name: 'SickKepuShennei',
    path: '/SickKepuShennei',
    component: SickKepuShennei,
    meta: {
      title: '神经内科'
    }
  },
  // SickKepuShenwai
  {
    name: 'SickKepuShenwai',
    path: '/SickKepuShenwai',
    component: SickKepuShenwai,
    meta: {
      title: '神经外科'
    }
  },
  // AdminHospitalDakaRecordPage
  {
    name: 'AdminHospitalDakaRecordPage',
    path: '/AdminHospitalDakaRecordPage',
    component: AdminHospitalDakaRecordPage,
    meta: {
      title: '打卡记录'
    }
  },
  // BaseVideoPlayPage
  {
    name: 'BaseVideoPlayPage',
    path: '/BaseVideoPlayPage',
    component: BaseVideoPlayPage,
    meta: {
      title: '视频播放'
    }
  },
  // BedPayKepu
  {
    name: 'BedPayKepu',
    path: '/BedPayKepu',
    component: BedPayKepu,
    meta: {
      title: '床边结算'
    }
  },
  // SickKepu
  {
    name: 'SickKepu',
    path: '/SickKepu',
    component: SickKepu,
    meta: {
      title: '疾病科普宣教'
    }
  },
  // RuyuanAndChuyuanKepu
  {
    name: 'RuyuanAndChuyuanKepu',
    path: '/RuyuanAndChuyuanKepu',
    component: RuyuanAndChuyuanKepu,
    meta: {
      title: '入院出院宣教'
    }
  },
  // QuickPayNormalPage
  {
    name: 'QuickPayNormalPage',
    path: '/QuickPayNormalPage',
    component: QuickPayNormalPage,
    meta: {
      title: '快捷支付'
    }
  },
  // FeatureTutorial
  {
    name: 'FeatureTutorial',
    path: '/tutorial/:role/:featureId',
    component: FeatureTutorial,
    props: true,
    meta: {
      title: '功能教程'
    }
  },
  // UserGuide
  {
    name: 'UserGuide',
    path: '/UserGuide',
    component: UserGuide,
    meta: {
      title: '用户指南'
    }
  },
  // DistributionDetailsPage
  {
    name: 'DistributionDetailsPage',
    path: '/DistributionDetailsPage',
    component: DistributionDetailsPage,
    meta: {
      title: '分销详情'
    }
  },
  // DistributionUserPage
  {
    name: 'DistributionUserPage',
    path: '/DistributionUserPage',
    component: DistributionUserPage,
    meta: {
      title: '分销个人中心'
    }
  },
  // DistributionRegisterPage
  {
    name: 'DistributionRegisterPage',
    path: '/DistributionRegisterPage',
    component: DistributionRegisterPage,
    meta: {
      title: '完善信息'
    }
  },
  // DistributionLoginPage
  {
    name: 'DistributionLoginPage',
    path: '/DistributionLoginPage',
    component: DistributionLoginPage,
    meta: {
      title: '分销登录'
    }
  },
  // MallMainPageV2
  {
    name: 'MallMainPageV2',
    path: '/MallMainPageV2',
    component: MallMainPageV2,
    meta: {
      title: '商城分销'
    }
  },
  // PatientDataHomePage
  {
    name: 'PatientDataHomePage',
    path: '/PatientDataHomePage',
    component: PatientDataHomePage,
    meta: {
      title: '患者打卡主页'
    }
  },
  // MallOrderListPage
  {
    name: 'MallOrderListPage',
    path: '/MallOrderListPage',
    component: MallOrderListPage,
    meta: {
      title: '订单列表'
    }
  },
  // ExchangeRecordPage
  {
    name: 'ExchangeRecordPage',
    path: '/ExchangeRecordPage',
    component: ExchangeRecordPage,
    meta: {
      title: '兑换记录'
    }
  },
  // ExchangeSuccessPage
  {
    name: 'ExchangeSuccessPage',
    path:
      '/ExchangeSuccessPage',
    component:
    ExchangeSuccessPage,
    meta:
      {
        title: '兑换成功'
      }
  },
  // ExchangeRemoteServicePage
  {
    name: 'ExchangeRemoteServicePage',
    path:
      '/dh01',
    component:
    ExchangeRemoteServicePage,
    meta:
      {
        title: '远程服务兑换'
      }
  },
  // EnterpiseMainPage
  {
    name: 'EnterpiseMainPage',
    path:
      '/EnterpiseMainPage',
    component:
    EnterpiseMainPage,
    meta:
      {
        title: '企业主页'
      }
  },
  // EnterpriseLoginPage
  {
    name: 'EnterpriseLoginPage',
    path:
      '/EnterpriseLoginPage',
    component:
    EnterpriseLoginPage,
    meta:
      {
        title: '企业登录'
      }
  },
  // AllianceIntroPage
  {
    name: 'AllianceIntroPage',
    path:
      '/AllianceIntroPage',
    component:
    AllianceIntroPage,
    meta:
      {
        title: '联盟介绍'
      }
  },
  // AdminHospitalPerformanceStatistics
  {
    name: 'AdminHospitalPerformanceStatistics',
    path:
      '/AdminHospitalPerformanceStatistics',
    component:
    AdminHospitalPerformanceStatistics,
    meta:
      {
        title: '业绩统计'
      }
  },
  // BigScreenMonitor
  {
    name: 'BigScreenMonitor',
    path:
      '/BigScreenMonitor',
    component:
    BigScreenMonitor,
    meta:
      {
        title: '大屏监控'
      }
  },
  // DoctorPatientPaymentBillListForPatient
  {
    name: 'DoctorPatientPaymentBillListForPatient',
    path:
      '/DoctorPatientPaymentBillListForPatient',
    component:
    DoctorPatientPaymentBillListForPatient,
    meta:
      {
        title: '我的缴费单'
      }
  },
  // SalePageFor480
  {
    name: 'SalePageFor480',
    path:
      '/SalePageFor480',
    component:
    SalePageFor480,
    meta:
      {
        title: '家庭病床康复'
      }
  },
  // DoctorPatientPaymentBillCreate
  {
    name: 'DoctorPatientPaymentBillCreate',
    path:
      '/DoctorPatientPaymentBillCreate',
    component:
    DoctorPatientPaymentBillCreate,
    meta:
      {
        title: '创建缴费单'
      }
  },
  // QuickPayPage
  {
    name: 'QuickPayPage',
    path:
      '/QuickPayPage',
    component:
    QuickPayPage,
    meta:
      {
        title: '快速支付'
      }
  },
  // QuickPayPage
  {
    name: 'QuickPayPageHuizhoupeixun01',
    path:
      '/QuickPayPage/huizhoupeixun01',
    component:
    QuickPayPageHuizhoupeixun01,
    meta:
      {
        title: '快截支付'
      }
  },
  // ClerkPage
  {
    name: 'ClerkPage',
    path:
      '/ClerkPage',
    component:
    ClerkPage,
    meta:
      {
        title: '录入员主页'
      }
  },
  // ClerkLogin
  {
    name: 'ClerkLogin',
    path:
      '/ClerkLogin',
    component:
    ClerkLogin,
    meta:
      {
        title: '录入员登录'
      }
  },
  // AdminHospitalPatientInputList
  {
    name: 'AdminHospitalPatientInputList',
    path:
      '/AdminHospitalPatientInputList',
    component:
    AdminHospitalPatientInputList,
    meta:
      {
        title: '患者录入记录表'
      }
  },
  // NeimengPatientBaseInfoForm
  {
    name: 'NeimengPatientBaseInfoForm',
    path:
      '/NeimengPatientBaseInfoForm',
    component:
    NeimengPatientBaseInfoForm,
    meta:
      {
        title: '录入患者基本信息'
      }
  },
  // DoctorListPage
  {
    name: 'DoctorListPage',
    path:
      '/DoctorListPage',
    component:
    DoctorListPage,
    meta:
      {
        title: '医生列表'
      }
  },
  // OrderHomePage
  {
    name: 'OrderHomePage',
    path:
      '/OrderHomePage',
    component:
    OrderHomePage,
    meta:
      {
        title: '我的订单'
      }
  },
  // MyKepuXuanJiaoMainPage
  {
    name: 'MyKepuXuanJiaoMainPage',
    path:
      '/MyKepuXuanJiaoMainPage',
    component:
    MyKepuXuanJiaoMainPage,
    meta:
      {
        title: '我的课程'
      }
  },
  // MallRentOrderConfirmPage 的路由
  {
    name: 'MallRentOrderConfirmPage',
    path:
      '/MallRentOrderConfirmPage',
    component:
    MallRentOrderConfirmPage,
    meta:
      {
        title: '辅具租赁确认'
      }
  },
  // MallRentDetailPage 的路由
  {
    name: 'MallRentDetailPage',
    path:
      '/MallRentDetailPage',
    component:
    MallRentDetailPage,
    meta:
      {
        title: '辅具租赁详情'
      }
  },
  // MallRentMainPageNeimeng
  {
    name: 'MallRentMainPageNeimeng',
    path:
      '/MallRentMainPageNeimeng',
    component:
    MallRentMainPageNeimeng,
    meta:
      {
        title: '辅具租赁'
      }
  },
  // MallMainPageNeimeng 的路由
  {
    name: 'MallMainPageNeimeng',
    path:
      '/MallMainPageNeimeng',
    component:
    MallMainPageNeimeng,
    meta:
      {
        title: '康复辅具'
      }
  },
  // MonitorAdminLabel 的路由
  {
    name: 'MonitorAdminLabel',
    path:
      '/MonitorAdminLabel',
    component:
    MonitorAdminLabel,
    meta:
      {
        title: '监控管理-告警标注'
      }
  },
  // KepuFromDoctorPage的路由
  {
    name: 'KepuFromDoctorPage',
    path:
      '/KepuFromDoctorPage',
    component:
    KepuFromDoctorPage,
    meta:
      {
        title: '科普宣教'
      }
  },
  // SingleToolKepuPage的路由
  {
    name: 'SingleToolKepuPage',
    path:
      '/SingleToolKepuPage',
    component:
    SingleToolKepuPage,
    meta:
      {
        title: '辅具科普'
      }
  },
  // KepuxuanjiaoMainPage的路由
  {
    name: 'KepuxuanjiaoMainPage',
    path:
      '/KepuxuanjiaoMainPage',
    component:
    KepuxuanjiaoMainPage,
    meta:
      {
        title: '科普宣教'
      }
  },
  // ToolKepuHomePage的路由
  {
    name: 'ToolKepuHomePage',
    path:
      '/ToolKepuHomePage',
    component:
    ToolKepuHomePage,
    meta:
      {
        title: '辅具科普'
      }
  },
  // MallPayResultPage的路由
  {
    name: 'MallPayResultPage',
    path:
      '/MallPayResultPage',
    component:
    MallPayResultPage,
    meta:
      {
        title: '支付结果'
      }
  },
  // PayPage的路由
  {
    name: 'PayPage',
    path:
      '/PayPage',
    component:
    PayPage,
    meta:
      {
        title: '支付'
      }
  },
  // MallOrderConfirmPage的路由
  {
    name: 'MallOrderConfirmPage',
    path:
      '/MallOrderConfirmPage',
    component:
    MallOrderConfirmPage,
    meta:
      {
        title: '确认订单'
      }
  },
  // MallDetailPage的路由
  {
    name: 'MallDetailPage',
    path:
      '/MallDetailPage',
    component:
    MallDetailPage,
    meta:
      {
        title: '商品详情'
      }
  },
  // MallMainPage的路由
  {
    name: 'MallMainPage',
    path:
      '/MallMainPage',
    component:
    MallMainPage,
    meta:
      {
        title: '辅具严选'
      }
  },
  // HospitalAdminListPage的路由
  {
    name: 'HospitalAdminListPage',
    path:
      '/HospitalAdminListPage',
    component:
    HospitalAdminListPage,
    meta:
      {
        title: '医院管理入口'
      }
  },
  // GuidePage的路由
  {
    name: 'GuidePage',
    path:
      '/guide',
    component:
    GuidePage,
    meta:
      {
        title: '居家康复'
      }
  },
  // FreePatientOrderAddPage的路由
  {
    name: 'FreePatientOrderAddPage',
    path:
      '/FreePatientOrderAddPage',
    component:
    FreePatientOrderAddPage,
    meta:
      {
        title: '免费下单'
      }
  },
  // AdminHospitalBookingList的路由
  {
    name: 'AdminHospitalBookingList',
    path:
      '/AdminHospitalBookingList',
    component:
    AdminHospitalBookingList,
    meta:
      {
        title: '管理员主页'
      }
  },
  // BookingManager的路由
  {
    name: 'BookingManager',
    path:
      '/BookingManager',
    component:
    BookingManager,
    meta:
      {
        title: '管理员主页'
      }
  },
  // AdminHospitalRemoteGuideList的路由
  {
    name: 'AdminHospitalRemoteGuideList',
    path:
      '/AdminHospitalRemoteGuideList',
    component:
    AdminHospitalRemoteGuideList,
    meta:
      {
        title: '管理员主页'
      }
  },
  // AdminHospitalPlanList的路由
  {
    name: 'AdminHospitalPlanList',
    path:
      '/AdminHospitalPlanList',
    component:
    AdminHospitalPlanList,
    meta:
      {
        title: '管理员主页'
      }
  },
  // AdminHospitalPatientList的路由
  {
    name: 'AdminHospitalPatientList',
    path:
      '/AdminHospitalPatientList',
    component:
    AdminHospitalPatientList,
    meta:
      {
        title: '管理员主页'
      }
  },
  // AdminHospitalDoctorList的路由
  {
    name: 'AdminHospitalDoctorList',
    path:
      '/AdminHospitalDoctorList',
    component:
    AdminHospitalDoctorList,
    meta:
      {
        title: '管理员主页'
      }
  },
  // AdminHopspital的路由
  {
    name: 'AdminHospital',
    path:
      '/AdminHospital',
    component:
    AdminHospital,
    meta:
      {
        title: '管理员主页'
      }
  },
  // PayServe1的路由
  {
    name: 'PayServe1',
    path:
      '/payserve1',
    component:
    PayServe1,
    meta:
      {
        title: '远程康复服务预约'
      }
  },
  // SearchUserPage的路由
  {
    name: 'SearchUserPage',
    path:
      '/searchuser',
    component:
    SearchUserPage,
    meta:
      {
        title: '搜索用户'
      }
  },
  // BookingListPage的路由
  {
    name: 'BookingListPage',
    path:
      '/bookinglist',
    component:
    BookingListPage,
    meta:
      {
        title: '预约列表'
      }
  },
  // MDTCreatePage的路由
  {
    name: 'MDTCreatePage',
    path:
      '/mdtcreate',
    component:
    MDTCreatePage,
    meta:
      {
        title: '创建MDT'
      }
  },
  // DoctorBookingTimeSelectPage的路由
  {
    name: 'DoctorBookingTimeSelectPage',
    path:
      '/doctorbookingtimeselect',
    component:
    DoctorBookingTimeSelectPage,
    meta:
      {
        title: '选择预约时间'
      }
  },
  // SelectDoctorPage的路由
  {
    name: 'SelectDoctorPage',
    path:
      '/selectdoctor',
    component:
    SelectDoctorPage,
    meta:
      {
        title: '选择医生'
      }
  },
  {
    name: 'bookforpatient',
    path:
      '/bookforpatient',
    component:
    PatientList,
    meta:
      {
        title: '直接预约'
      }
  },
  {
    name: 'WebOrderListPage',
    path:
      '/WebOrderListPage',
    component:
    WebOrderListPage,
    meta:
      {
        title: '网页订单列表'
      }
  },
  {
    name: 'AdminHomePage',
    path:
      '/AdminHomePage',
    component:
    AdminHomePage,
    meta:
      {
        title: '管理员主页'
      }
  },
  {
    name: 'MonitoringScreen',
    path:
      '/MonitoringScreen/01',
    component:
    MonitoringScreen,
    meta:
      {
        title: '居家照护'
      }
  },
  {
    name: 'WebPage',
    path:
      '/WebPage',
    component:
    WebPage,
    meta:
      {
        title: '居家康复'
      }
  },
  {
    name: 'DemoPage',
    path:
      '/',
    component:
    Index,
    meta:
      {
        title: '居家康复'
      }
  },
  {
    name: 'MdtHome',
    path:
      '/mdt/home',
    component:
    MdtHome,
    meta:
      {
        title: '远程康复指导服务'
      }
  },
  {
    name: 'LoginPage',
    path:
      '/login/index',
    component:
    LoginPage,
    meta:
      {
        title: '登录'
      }
  },
  {
    name: 'HospitalSelectPage',
    path:
      '/login/hospitalSelect',
    component:
    HospitalSelectPage,
    meta:
      {
        title: '服务机构'
      }
  },
  {
    name: 'SickKindSelectPage',
    path:
      '/login/sickKindSelect',
    component:
    SickKindSelectPage,
    meta:
      {
        title: '康复类型选择'
      }
  },
  {
    name: 'DoctorSelectPage',
    path:
      '/login/doctorSelectPage',
    component:
    DoctorSelectPage,
    meta:
      {
        title: '服务人员选择'
      }
  },
  {
    name: 'UserBaseInfoEntryPage',
    path:
      '/login/userBaseInfoEntryPage',
    component:
    UserBaseInfoEntryPage,
    meta:
      {
        title: '基本信息'
      }
  },
  {
    name: 'MdtVideo',
    path:
      '/mdt/video',
    component:
    MdtVideo,
    meta:
      {
        title: 'MDT群视频'
      }
  },
  {
    name: 'MdtVideoWarning',
    path:
      '/mdt/video/warning',
    component:
    MdtWarning,
    meta:
      {
        title: 'MDT群视频'
      }
  },
  {
    name: 'ApplyGymnastics',
    path:
      '/apply/gymnastics',
    component:
    ApplyGymnastics,
    meta:
      {
        title: '申请体操'
      }
  },
  {
    name: 'PlanGymnasticsSelPage',
    path:
      '/plangymnasticsSel',
    component:
    PlanGymnasticsSelPage,
    meta:
      {
        title: '方案中心'
      }
  },
  {
    name: 'GymnasticsListPage',
    path:
      '/gymnasticsListPage',
    component:
    GymnasticsListPage,
    meta:
      {
        title: '方案中心'
      }
  },
  {
    name: 'GymnasticsDetailPage',
    path:
      '/GymnasticsDetailPage',
    component:
    GymnasticsDetailPage,
    meta:
      {
        title: '方案中心'
      }
  },
  {
    name: 'PlanListPage',
    path:
      '/PlanListPage',
    component:
    PlanListPage,
    meta:
      {
        title: '方案中心'
      }
  },
  {
    name: 'PlanDetailPage',
    path:
      '/PlanDetailPage',
    component:
    PlanDetailPage,
    meta:
      {
        title: '方案中心'
      }
  },
  {
    name: 'Home1',
    path:
      '/home1',
    component:
    Home1,
    meta:
      {
        title: '居家康复'
      }
  },
  {
    name: 'HomeApplyFszyy',
    path:
      '/HomeApplyFszyy',
    component:
    HomeApplyFszyy,
    meta:
      {
        title: '居家康复'
      }
  },
  {
    name: 'HomeApplyZxfwzz',
    path:
      '/HomeApplyZxfwzz',
    component:
    HomeApplyZxfwzz,
    meta:
      {
        title: '家庭康复'
      }
  },
  {
    name: 'Square',
    path:
      '/jjkfkp/square',
    component:
      resolve => require(['./pages/home/Square'], resolve),
    meta:
      {
        title: '居家康复科普'
      }
  },
  {
    path: '/hospital_intro/doucan',
    component:
    HospitalIntroDoucan,
    meta:
      {
        // 页面标题title
        title: '居家康复'
      }
  },
  {
    path: '/products/gmdss',
    component:
    gmdss,
    meta:
      {
        // 页面标题title
        title: '产品-骨密度康复'
      }
  },
  {
    name: 'KepuColumn',
    path:
      '/jjkfkp/KepuColumn',
    component:
    KepuColumn,
    meta:
      {
        title: '居家康复学组专栏'
      }
  },
  {
    name: 'KepuLive',
    path:
      '/jjkfkp/KepuLive',
    component:
    KepuLive,
    meta:
      {
        title: '康复直播'
      }
  },
  {
    name: 'KepuLive',
    path:
      '/ticaoList',
    component:
    TicaoList,
    meta:
      {
        title: '康复体操'
      }
  },
  {
    name: 'TicaoDetailPage',
    path:
      '/TicaoDetailPage',
    component:
    TicaoDetailPage,
    meta:
      {
        title: '康复体操播放'
      }
  },
  {
    name: 'SalePageDongguangkanghua01',
    path:
      '/salepage/SalePageDongguangkanghua01',
    component:
    SalePageDongguangkanghua01,
    meta:
      {
        title: '家庭病床康复'
      }
  },
  {
    name: 'SalePage20230001',
    path:
      '/salepage/20230001',
    component:
    SalePage20230001,
    meta:
      {
        title: '家庭病床康复'
      }
  },
  {
    name: 'SalePage20230001Step2',
    path:
      '/salepage/20230001/step2',
    component:
    SalePage20230001Step2,
    meta:
      {
        title: '家庭病床康复-服务预约'
      }
  },
  {
    name: 'SalePage20230001Result',
    path:
      '/salepage/20230001/result',
    component:
    SalePage20230001Result,
    meta:
      {
        title: '家庭病床康复-支付结果'
      }
  },
  {
    name: 'DefaultHospitalDownloadPage',
    path:
      '/hospital/download',
    component:
    DefaultHospitalDownloadPage,
    meta:
      {
        title: '远程居家康复APP下载'
      }
  },
  {
    name: 'detail',
    path:
      '/feeds/detail/:contentId',
    component:
    DetailPage,
    meta:
      {
        title: '文章详情'
      }
  },
  {
    name: 'ServeSale202301', // 基金会公益活动 2023年
    path:
      '/ServeSale202301',
    component:
    ServeSale202301,
    meta:
      {
        title: '中风患者家庭康复科普公益活动'
      }
  },
  {
    name: 'ServeSale202301Step1', // 基金会公益活动 2023年
    path:
      '/ServeSale202301/detail/1',
    component:
    ServeSale202301Step1,
    meta:
      {
        title: '资助详情'
      }
  },
  {
    name: 'ServeSale202301Step2', // 基金会公益活动 2023年
    path:
      '/ServeSale202301/detail/2',
    component:
    ServeSale202301Step2,
    meta:
      {
        title: '资助详情'
      }
  },
  {
    name: 'ServeSale202301Step2NotFree', // 基金会公益活动 2023年
    path:
      '/ServeSale202301/detail/2/needpay',
    component:
    ServeSale202301NotFree,
    meta:
      {
        title: '资助详情'
      }
  },
  {
    name: 'ServeSale202301Step3', // 基金会公益活动 2023年
    path:
      '/ServeSale202301/detail/3',
    component:
    ServeSale202301Step3,
    meta:
      {
        title: '资助详情'
      }
  },
  {
    name: 'HomePage',
    path:
      '/home',
    component:
    DoucanHome,
    meta:
      {
        title: '居家康复'
      },
    redirect: '/home1', // redirect: '/home/home',
    children:
      [{
        name: 'Home',
        path: '/home/home',
        component: Home,
        meta: {
          title: '居家康复'
        }
      }, {
        name: 'Square',
        path: '/home/square',
        component: Square,
        meta: {
          title: '康复圈'
        }
      }, {
        name: 'Person',
        path: '/home/person',
        component: Person,
        meta: {
          title: '个人中心'
        }
      }]
  },
  {
    name: 'HongqianTabbarHome',
    path:
      '/hongqian',
    component:
    HongqianTabbarHome,
    meta:
      {
        title: '颐康中心'
      },
    children:
      [{
        name: 'HongQianHome',
        path: '/hongqian/home',
        // redirect: '/healthstation/home',
        component: HongQianHomePage,
        meta: {
          title: '颐康中心'
        }
      }, {
        name: 'HongQianPerson',
        path: '/hongqian/person',
        component: HongqianPerson,
        meta: {
          title: '个人中心'
        }
      }]
  },
  {
    name: 'HealthStationTabbarHome',
    path:
      '/healthstation',
    component: HealthStationHome,
    meta:
      {
        title: '社区卫生服务站'
      },
    children:
      [{
        name: 'HealthStationHome',
        path: '/healthstation/home',
        component: HealthStationHomePage,
        meta: {
          title: '首页'
        }
      }, {
        name: 'HealthStationPerson',
        path: '/healthstation/person',
        component: HealthStationPerson,
        meta: {
          title: '个人中心'
        }
      }]
  },
  {
    path: '*',
    component:
    NotFoundPage
  }
]

// const router = new VueRouter({ routes })
const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 如果 `savedPosition` 存在，说明这是通过浏览器的前进/后退按钮触发的导航，
    // 在这种情况下，返回 `savedPosition` 可以让页面保持在原来的滚动位置。
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    //   // 如果没有 `savedPosition`，返回顶部
    //   return {
    //     x: 0,
    //     y: 0
    //   }
    // }
    return savedPosition
  }
})
router.beforeEach((to, _, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'DEMO'
  }
  return next()
})

export default router
