<template>
  <div class="scroll-container">
    <div style="position:absolute;top: 8px;left: 8px;font-size: 12px;color: rgba(55,55,55,0.51)">社区卫生服务站</div>
    <div
      style="display: flex;flex-direction: column;height: 100%;opacity: 1;background: linear-gradient(197deg, #F59D34 0%, rgba(255, 255, 255, 0) 53%, rgba(255, 255, 255, 0) 53%), #F5F6FA;">
      <div style="height: 32px"/>
      <div style="display: flex;align-items: center" @click="onUserAreaClick">

        <my-img style="width: 100px;" url="https://cdn.keihong.tech/img/202411162123313.png"/>
        <div style="display: flex;flex-direction: column">
          <div>亲~ {{ getGreeting() }}好</div>
          <div v-if="name" style="font-size: 12px;color: #333333">当前用户：{{ name }}</div>
          <div v-else>请点击登录~</div>
        </div>
      </div>
      <div style="padding: 16px">
        <div style="padding: 16px 0;background-color: white;border-radius: 16px;">

          <van-cell
            v-for="(item, index) in menuList"
            :key="index"
            is-link
            @click="handleFuntionMenuClick(item)"
          >
            <template #icon>
              <img :src="item.icon" alt="icon" class="custom-icon"/>
            </template>
            <template #title>
              <span class="custom-title">{{ item.title }}</span>
            </template>
          </van-cell>
        </div>
      </div>
      <div style="padding: 16px">
        <van-button style="width: 100%;border-radius: 16px;border-width: 0" @click="logout">退出账号</van-button>

      </div>
      <div style="flex: 1;"></div>
      <div style="font-size: 12px;color: #505050;text-align: center;margin-bottom: 16px">技术支持：动优科技</div>
    </div>

  </div>
</template>

<script>
import Grid from '@/h5/doucan/components/Grid.vue'
import MyImg from '@/h5/doucan/components/MyImg.vue'

export default {
  components: {
    MyImg,
    Grid
  },
  data () {
    return {
      menuList: [
        {
          icon: 'https://cdn.keihong.tech/202311241620941.png',
          title: '订单中心'
        }, {
          icon: 'https://cdn.keihong.tech/img/202405171350587.png',
          title: '操作指南'
        }],
      name: '',
      iosVer: ''
    }
  },
  mounted () {
    if (this.$store.state.user.profile.isLogin) {
      this.name =
        this.$store.state.user.profile.name +
        '（' +
        this.$store.state.user.profile.phone.slice(7, 11) +
        '）'
    }
  },
  methods: {
    getGreeting () {
      const currentHour = new Date().getHours()
      console.log('getGreeting', currentHour)
      if (currentHour < 12) {
        return '早上'
      } else if (currentHour < 13) {
        return '中午'
      } else if (currentHour < 18) {
        return '下午'
      } else {
        return '晚上'
      }
    },
    onUserAreaClick () {
      if (!this.checkLogin()) {
        this.$router.push('/login/index')
      }
    },
    handleFuntionMenuClick (item, index) {
      console.log('点击', item, index)
      if (item.title === '订单中心') {
        this.$router.push('/OrderHomePage')
      }
      if (item.title === '操作指南') {
        this.$router.push('/UserGuide?showpatient=true')
      }
    },
    toHomeServiceApply () {
      this.$router.push('/HomeApplyZxfwzz')
    },
    toOtherService () {
      window.location.href = 'https://www.wjx.cn/vm/OPAidTV.aspx?v=13'
    },
    toDaka () {
      if (!this.checkLogin()) {
        this.$router.push('/login/index')
        return
      }
      this.$router.push('/PatientDataHomePage')
    },
    toApplyGymnastics () {
      if (!this.checkLogin()) {
        this.$router.push('/login/index')
        return
      }
      this.$router.push('/apply/gymnastics')
    },
    toPlan () {
      if (!this.checkLogin()) {
        this.$router.push('/login/index')
        return
      }
      this.$router.push('/plangymnasticsSel')
    },
    toMdt () {
      if (!this.checkLogin()) {
        this.$router.push('/login/index')
        return
      }
      this.$router.push('/mdt/home')
    },
    checkLogin () {
      return this.$store.state.user.profile.isLogin
    },
    logout () {
      // this.$store.commit('user/setQrCodeHospital', '')
      this.$store.commit('user/setOpenId', '')
      this.$store.commit('user/logout')
      location.reload()
    }
  }
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
}

/* 自定义图标样式 */
.custom-icon {
  width: 24px; /* 调整图标宽度 */
  height: 24px; /* 调整图标高度 */
  display: inline-block;
  vertical-align: middle; /* 确保图标与文字垂直居中 */
}

/* 自定义标题样式 */
.custom-title {
  vertical-align: middle; /* 确保文字与图标垂直居中 */
  font-size: 16px; /* 可根据需求调整字体大小 */
  margin-left: 8px; /* 图标与文字间距 */
}

.scroll-container {
  width: 100%; /* 设置宽度 */
  height: 94vh;
  overflow-y: auto; /* 启用垂直滚动 */

}

/* 隐藏滚动条的样式 */
.scroll-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari 隐藏滚动条 */
}

.scroll-container {
  -ms-overflow-style: none; /* IE 隐藏滚动条 */
  scrollbar-width: none; /* Firefox 隐藏滚动条 */
}
</style>
