<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="拼团">
      <!--      <div style="width: 100vw;background-color: #0a4b3d;height: 100px"></div>-->
      <my-img style="width: 100vw" url="https://cdn.keihong.tech/img/202501101805014.png"/>

    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import MyImg from '@/h5/doucan/components/MyImg.vue'

export default {
  name: 'PingTuanHomePage',
  components: {
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {}
  },
  mounted () {
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
