<template>
  <div>
    <!--    <div class="title-bar">付款</div>-->
    <base-title-bar-scroll-page ref="container" :back="true" :show-title-bar="showTitleBar" back-text="主页"
                                title="远程康复绿通服务"
                                @onBackClick="onBackClick">

      <my-img style="width: 100%" url="https://cdn.keihong.tech/img/202412221730992.png"/>
      <div @click="onPay89Click(1)">
        <my-img style="width: 100%" url="https://cdn.keihong.tech/img/202411182126830.png"/>
      </div>
      <my-img style="width: 100%" url="https://cdn.keihong.tech/img/202412221719318.png"/>
      <div @click="onPay89Click(2)">
        <my-img style="width: 100%" url="https://cdn.keihong.tech/img/202411182132546.png"/>
      </div>

      <div style="text-align: center;font-size: 14px;color: #494949">技术支持：动优远程康复服务云平台</div>
    </base-title-bar-scroll-page>
    <van-popup v-model="showInput" class="popup-wrapper" position="bottom"
               style="height: 50vh">
      <div v-if="goodsType===1">
        *您正在购买【服务1-89元康复专家1v1视频面诊服务】
      </div>
      <div v-if="goodsType===2">
        *您正在购买【服务2-289元30天远程康复指导服务】
      </div>

      <div class="input-wrapper">
        <van-field
          v-model="patientName"
          label="姓名"
          placeholder="请输入姓名"
          required
          type="text"
        />
        <van-field
          v-model="patientMobile"
          label="手机号"
          placeholder="请输入手机号"
          required
          type="tel"
        />
      </div>
      <!--        <div class="textarea-wrapper">-->
      <!--          <van-field-->
      <!--            v-model="remarks"-->
      <!--            placeholder="请输入备注"-->
      <!--            rows="5"-->
      <!--            style=" border: 1px solid #ccc;border-radius: 8px;"-->
      <!--            type="textarea"-->
      <!--          />-->
      <!--        </div>-->
      <div class="button-wrapper">
        <van-button style="width: 100%;margin-bottom: 16px" type="primary" @click="submitPayment">立即支付
        </van-button>
      </div>
    </van-popup>
    <van-popup v-model="showPopup" :close-on-click-overlay="false" class="popup-wrapper" position="bottom">
      <div style="padding: 16px;text-align: center">
        <img alt="Success Logo" class="success-logo" src="https://cdn.keihong.tech/img/202312211813200.png">
        <div class="success-message">支付成功</div>
        <div style=" text-align: center;font-size: 14px;margin: 20px 0;">订单编号：{{ outTradeNo }}</div>
        <div style=" text-align: center;font-size: 14px;margin: 20px 0;">{{ orderCreateTime }}</div>
        <div style=" text-align: center;font-size: 18px;margin: 20px 0;">支付服务</div>
        <div style=" font-size: 14px;margin: 20px 0;text-align: left">{{ this.remark }}</div>

        <div style="color: #272727;text-align: left;font-size: 18px">
          <my-img style="width: 100%" url="https://cdn.keihong.tech/img/202411182219794.png"/>
        </div>
        <van-button style="width: 100%;margin-top: 16px" type="primary" @click="refreshPage">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>

import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { Dialog, Notify, Toast } from 'vant'
import WxPayDao from '@/h5/doucan/network/WxPayDao'
import wx from 'weixin-jsapi'
import axios from 'axios'
import DateUtils from '@/utils/DateUtils'
import MyImg from '@/h5/doucan/components/MyImg.vue'

export default {
  name: 'DonglingPay289',
  components: {
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      envStatus: '',
      from: '',
      showTitleBar: true,
      showPopup: false,
      hospital: null,
      hospitalId: null,
      isExpert: false,
      amount: '',
      remarks: '',
      patientName: '',
      patientMobile: '',
      testMobile: ['18008000808', '13610164743', '18520535303', '18501011010'],
      openId: '',
      payStatus: false,
      appsecret: '',
      appid: '',
      code: '',
      openid: '',
      paySignInfo: null,
      outTradeNo: '', // 动优支付系统里面的订单号 ordernum
      remark: '',
      form: {
        count: 1,
        price: 200
        // deposit: 1200
      },
      orderCreateTime: null,
      showInput: false,
      goodsType: null
    }
  },
  mounted () {
    // if (window.uni) {
    //   const _that = this
    //   window.uni.getEnv(function (res) {
    //     // console.log('当前环境：' + JSON.stringify(res))
    //     _that.envStatus = JSON.stringify(res)
    //   })
    // }

    this.hospitalId = this.$route.query.hospital_id
    const hospitalName = this.$route.query.hospitalName
    this.from = this.$route.query.from
    const phone = this.$route.query.phone
    const name = this.$route.query.name
    const openid = this.$route.query.openid

    if (this.hospitalId) {
      this.hospital = this.hospitals[this.hospitalId]
    }

    if (hospitalName) {
      // 查找 hospitals 中 name === hospitalName 的医院信息
      const findHospitalByName = (hospitals, hospitalName) => {
        for (const key in hospitals) {
          if (hospitals[key].name === hospitalName) {
            return hospitals[key]
          }
        }
        return null // 如果没有找到匹配的医院
      }
      this.hospital = findHospitalByName(this.hospitals, hospitalName)
    }

    // 当前没有医院信息，创建默认的医院信息
    if (!this.hospital) {
      this.hospital = {
        name: hospitalName,
        departments: [{
          id: 1,
          name: '默认科室',
          doctors: ['默认医生']
        }
        ]
      }
    }

    console.log('hospitalId: ' + this.hospitalId)
    console.log('hospital: ' + this.hospital)

    this.isExpert = this.$route.query.expert

    if (this.hospital.price) {
      this.form.price = this.hospital.price
    }
    if (this.isExpert) {
      this.hospital.notShowPic = true
      this.form.price = this.hospital.expertPrice
      this.hospital.departments = this.hospital.expertDepartments
    }

    if (this.hospital.departments.length === 1) {
      this.selectedDepartment = this.hospital.departments[0]
      if (this.selectedDepartment.doctors.length === 1) {
        this.selectedDoctor = this.selectedDepartment.doctors[0]
      }
    }

    this.$refs.container.stopLoading()

    // if (this.hospitalId) {
    //   HospitalDao.getDoctorsForSelect(this.hospitalId).then(response => {
    //     this.hospitals[this.hospitalId].doctors = response
    //     console.log('doctors: ' + response)
    //   })
    // }

    // 判断是否是微信浏览器
    const isWeChatBrowser = () => {
      const userAgent = navigator.userAgent.toLowerCase()
      return userAgent.includes('micromessenger')
    }

    if (this.from === 'wxapp') {
      this.showTitleBar = false
      // this.patientMobile = phone
      // this.patientName = name
      this.openid = openid
      // this.envStatus = this.openid
    } else {
      if (isWeChatBrowser()) {
        // console.log("This is a WeChat browser");
        this.initWx()
      } else {
        // console.log("This is not a WeChat browser");
      }
    }

    // const ua = window.navigator.userAgent.toLowerCase()
    // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //   // alert('是微信浏览器')
    //   // 初始化微信-获取openid
    //   this.initWx()
    // }
  },
  methods: {
    onPay89Click (type) {
      this.goodsType = type
      this.showInput = true
    },
    onBackClick () {
      // 重定向到某个url
      if (this.hospital.name === '内蒙古益济堂养老服务有限公司') {
        this.hospital.name = '中国民间中医医药研究开发协会中蒙民族医药研究发展分会'
      }
      window.location.href = 'https://doucan.jianerkanggz.cn/#/home/square?hospital=' + this.hospital.name
    },
    refreshPage () {
      this.showPopup = false
      // location.reload()
      // window.location.href = 'https://doucan.jianerkanggz.cn/#/home/square?hospital=' + this.hospital
      if (this.from === 'wxapp') {
        // 小程序，返回到小程序首页
      } else {
        // 扫码网页版，返回到首页
        this.onBackClick()
      }
    },
    submitPayment () {
      // Add your payment submission logic here
      this.pay()
    },
    async initWx () {
      await this.getAppid()
      const openid = this.$store.state.user.openId
      if (openid) {
        this.openid = openid
      } else {
        await this.getMpOpenID()
      }
    },
    async pay () {
      if (!this.patientName || !this.patientMobile) {
        Notify({
          type: 'danger',
          message: '请填写姓名和手机号'
        })
        return
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      let fee = 0
      if (this.testMobile.includes(this.patientMobile)) {
        fee = 0.01
      } else {
        fee = this.totalPrice
      }
      const fromActivity = 'C端推广活动20241101'
      // const goods = this.hospital + '-' + this.remarks
      const oderType = this.goodsType === 1 ? '89元服务包' : '289元服务包'
      const goods = this.hospital.name + '-' + oderType + '-患:' + this.patientName
      console.log('goods', goods)
      // if (this.isAdmin) {
      //   fromActivity = '客服操作下单' + '-' + this.remark
      //   goods = this.hospital + '-' + this.doctorName + '-' + this.count + '周'
      // }

      // this.remark = '远程康复服务单-' + this.hospital + '-' + this.selectedDepartment.name + '-' + this.selectedDoctor + '-' + this.selectedSick

      this.remark = `${fromActivity}-${oderType}-${this.hospital.name}-${this.patientName}-${this.patientMobile}-${goods}`
      // this.showPopup = true
      console.log('remark', this.remark)
      // todo 根据 from === 'wxapp' 判断支付的逻辑
      const profile = {
        serveType: 0,
        mobile: this.patientMobile, // 后期mobile从用户授权获得，即订单记录支付者登录的微信账号手机号及实际接受服务患者的手机号
        patientName: this.patientName,
        patientMobile: this.patientMobile,
        orderType: '',
        fee: fee,
        fromActivity: fromActivity,
        goods: goods,
        openId: this.openid,
        doctor: this.selectedDoctor,
        sick: this.selectedSick,
        department: this.selectedDepartment.name,
        hospital: this.hospital.name,
        remark: this.remark
      }
      console.log('提交订单', profile)
      // 后台创建订单
      this.orderCreateTime = DateUtils.formateDate(new Date(), 'YYYY-MM-DD HH:mm:ss')
      const data = await WxPayDao.createOrder(profile, this.from === 'wxapp')
      console.log('支付qrcode', data)
      if (data.code === 1) {
        this.paySignInfo = data.data
        this.outTradeNo = data.data.outTradeNo

        if (this.from === 'wxapp') {
          // this.envStatus = JSON.stringify(this.paySignInfo)
          // 小程序支付
          /**
           * //在微信内，在小程序内。
           * console.log("小程序的支付业务")
           * let wxJson ={} // 微信返回的5个参数
           * wx.miniProgram.navigateTo({url: '"/pages/pay/pay?param=" + wxJson  '});
           * ————————————————
           * 原文链接：https://blog.csdn.net/m0_37572422/article/details/129270882
           */
          this.paySignInfo.orderInfo = {
            outTradeNo: this.outTradeNo,
            orderCreateTime: this.orderCreateTime,
            remark: this.remark
          }
          const wxJson = JSON.stringify(this.paySignInfo)
          const encodedParam = encodeURIComponent(wxJson)
          window.wx.miniProgram.navigateTo({
            url: `/pages/pay_submit_universe/pay_submit_universe?param=${encodedParam}`
          })
        } else {
          // 调起微信支付
          wx.config({
            // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false
            debug: false,
            // 必填，公众号的唯一标识
            appId: this.paySignInfo.sign.appId,
            // 必填，生成签名的时间戳
            timestamp: this.paySignInfo.sign.timeStamp,
            // 必填，生成签名的随机串
            nonceStr: this.paySignInfo.sign.nonceStr,
            // 必填，签名
            signature: this.paySignInfo.sign.paySign,
            // 必填，需要使用的JS接口列表
            jsApiList: ['chooseWXPay']
          })
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: ['chooseWXPay'],
              success: function (res) {
                console.log('seccess')
                console.log('hskdjskjk', res)
              },
              fail: function (res) {
                console.log('fail')
                console.log(res)
              }
            })
            const _that = this
            wx.chooseWXPay({
              // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              timestamp: this.paySignInfo.sign.timeStamp,
              // 支付签名随机串，不长于 32 位
              nonceStr: this.paySignInfo.sign.nonceStr,
              // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              package: this.paySignInfo.sign.packageValue,
              // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              signType: this.paySignInfo.sign.signType,
              // 支付签名
              paySign: this.paySignInfo.sign.paySign,
              // 支付成功后的回调函数
              success: function (res) {
                if (res.errMsg === 'chooseWXPay:ok') {
                  console.log('支付成功', res)
                  _that.payStatus = true
                  Dialog.alert({
                    message: '支付成功'
                  }).then(() => {
                    // on close
                    // todo 跳转到支付成功页面，显示支付凭证和提示
                    // _that.$router.push(
                    //   '/salepage/20230001/result?outTradeNo=' + _that.outTradeNo
                    // )
                    _that.showPopup = true
                  })
                } else {
                  Dialog({ message: JSON.stringify(res) })
                  _that.payStatus = false
                }
              },
              fail: function (res) {
                Dialog({ message: '支付失败:' + JSON.stringify(res) })
                _that.payStatus = false
              }
            })
          })
          wx.error((err) => {
            alert(err)
          })
        }
      } else {
        console.log('支付失败-订单创建失败', data)
      }
    },
    async getOpenId (code) {
      // this.info = '正在获取getOpenId'
      const url = '/quick/pay/openid?code=' + code

      axios
        .get(url)
        .then((res) => {
          // this.info = JSON.stringify(res)
          this.openid = res.data.data.openid
          console.log('openid = ', res.data.data.openid)
          if (!this.openid) {
            this.getCodeApi()
            return
          }
          this.$store.commit('user/setOpenId', this.openid)
          // const profile = this.$store.state.apply20230001.profile
          // profile.openId = this.openid
          // this.$store.commit('apply20230001/update', profile)
        })
        .catch((err) => {
          console.log('err', err)
          this.getCodeApi()
        })
    },
    getCodeApi () {
      const appid = 'wxcb56667c309e369b'
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      // const scope = 'snsapi_userinfo' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url =
        'https://open.weixin.qq.com' +
        '/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        urlNow +
        '&response_type=code&scope=' +
        scope +
        '#wechat_redirect'
      window.location.href = url
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [undefined, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    async getAppid () {
      const url = '/quick/pay/appid'
      const res = await axios.get(url)
      console.log('appid', res)
      this.appid = res.data.data.codeName
      this.appsecret = res.data.data.codeValue
    },
    async getMpOpenID () {
      const code = this.getUrlKey('code')
      if (code) {
        this.code = code
        await this.getOpenId(code)
      } else {
        this.getCodeApi()
      }
    },
    onDepartmentSelected () {
      console.log(this.selectedDepartment)
    }
  },
  watch: {
    'form.count' (val) {
      this.amount = val * this.form.price
      console.log('fee:', this.amount)
      // this.$emit('update-fee', this.fee, val)
    }
  },
  computed: {
    totalPrice () {
      return this.goodsType === 1 ? 89 : 289
    }
  }
}
</script>
<style scoped>

.logo {
  float: left;
  width: 60px;
  height: 60px;
}

.institution-name {
  float: right;
  width: 100%;
  font-size: 14px;
  margin-left: 16px;
}

.divider {
  height: 1px;
  background-color: #ccc;
  margin: 16px;
}

.inputBoxWrapper {
  margin: 16px;
}

.inputBox {
  border: 1px solid #ccc;
  border-radius: 16px;
}

.textarea-wrapper {
  margin: 16px;
}

.textarea-wrapper .van-field__control {
}

.button-wrapper {
  margin: 16px;

}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
  color: #7e7e7e;
}

.input-wrapper {
  margin: 16px;
}

.popup-wrapper {
  height: 80vh;
}

.success-logo {
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.success-message {
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
}

.right {
  float: right;
}
</style>
